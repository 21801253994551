import React from 'react';
import { ColumnsType } from 'antd/es/table';
import {WhatsappMetaContactList} from "models";
import TableList from 'components/shared/TableList';
import {PlusOutlined} from "@ant-design/icons";
import {Col, Row, Tag, Tooltip, Dropdown, Menu, Button, Typography} from "antd";
import {Link} from "react-router-dom";


const addNumberNuttonStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'end'
} ;

export interface WhatsappContactListProps {
  whatsappcontact: WhatsappMetaContactList[];
  openInstructionForm: ()=> void;
  openMigrationInstructionForm: () => void ;
}

const WhatsappContactNumberList = (props: WhatsappContactListProps) => {
  // eslint-disable-next-line no-unused-vars
  const { whatsappcontact, openInstructionForm, openMigrationInstructionForm } = props;

  const tableColumns: ColumnsType<WhatsappMetaContactList> = [
    {
      title: 'Display Name',
      dataIndex: 'name',
      render: (text, record) => (
        record.connection_status === 'PENDING' ? (
          <Tooltip  title="Your Connection status is Pending. Complete Your onboarding steps to access the detail page">
            <span>{text}</span>
          </Tooltip>
        ) : (
          <Link to={{pathname: `${record.number}` }}>{text}</Link>
        )
      )
    },
    {
      title: 'Number',
      dataIndex: 'number',
    },
    {
      title: 'Connection Status',
      dataIndex: 'connection_status',
      render: (text) => {
        let formattedText = text;
        if (text === 'CONNECTED') {
          formattedText = <Tag color="green"><span className="font-size-md font-weight-bold rounded">Connected</span></Tag>;
        } else if (text === 'FLAGGED') {
          formattedText = <Tag color="red"><span className="font-size-md font-weight-bold rounded">Flagged</span></Tag>;
        } else if (text === 'PENDING') {
          formattedText = <Tag color="yellow"><span className="font-size-md font-weight-bold rounded">Pending</span></Tag>;
        }
        return formattedText;
      },
    },
    {
      title: 'Country',
      dataIndex: 'country_code',
    },
    {
      title: 'Quality Rating',
      dataIndex: 'quality_rating',
      render: (text, record) => (
        <Row>
          <Col>
        <span style={{ display: "flex", width:"10px", height: "10px", backgroundColor: text?.toLowerCase(), borderRadius: "50%", marginTop:"5px"}}/>
          </Col>
          <Col>
            {/* eslint-disable-next-line no-nested-ternary */}
          <div style={{ marginLeft:"7px"}}>{(text==="GREEN")?"High":((text==="RED")?"Low":((text==="YELLOW")?"Medium":text))}</div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Messaging Limit',
      dataIndex: 'messaging_limit',
      render: (text) => {
        let formattedText = text;
        if (text === 'TIER_1K') {
          formattedText = '1K Customers/24hrs';
        } else if (text === 'TIER_10K') {
          formattedText = '10K Customers/24hrs';
        } else if (text === 'TIER_100K') {
          formattedText = '100K Customers/24hrs';
        }
        return formattedText;
      },
    },
    {
      title: 'Last Onboarded Time',
      dataIndex: 'last_onboarded_time',
    }
  ];

  const menu = (
    <Menu>
      <Menu.Item>
        <Typography.Text onClick={openInstructionForm}>Onboard new number</Typography.Text>
      </Menu.Item>
      <Menu.Item>
        <Typography.Text onClick={openMigrationInstructionForm}>Migrate existing number</Typography.Text>
      </Menu.Item>
    </Menu>
  );


  // const controls = [
  //   {
  //     title: 'Add Whatsapp Number',
  //     icon: <PlusOutlined/>,
  //     isPrimary: true,
  //     isIconOnly: true,
  //     onClick: () => {
  //       openInstructionForm?.();
  //     }
  //   }
  // ];
  

  return (
    <div>
      <div style={addNumberNuttonStyle}>
        <Dropdown overlay={menu} placement="bottomLeft">
          <Button type="primary">
            <PlusOutlined/>  Add Whatsapp Number
          </Button>
        </Dropdown>
        </div>

      <TableList<WhatsappMetaContactList> items={whatsappcontact} columns={tableColumns}
        rowKey={(record: WhatsappMetaContactList) => record.user_id} hideRowSelection />
    </div>
  );
};


export default WhatsappContactNumberList;
