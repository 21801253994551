import React, { useEffect, useState, useCallback } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';
import { Button, Card, Form, Input, Tooltip, Upload, message } from "antd";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { UploadFile } from 'antd/es/upload/interface';
import { uploadChatbotMediaFile } from 'services/dashboard/WhatsappService';
import { showErrorNotification } from 'utils/helper';
import useFormErrors from "../../../hooks/useFormErrors";

interface ImageNodeProps {
  id: string;
  data: {
    msg_type: 'ATTACHMENT';
    payload_info?: {
      url?: string;
      imageName?: string;
      caption?: string;
      content_type?: 'image';
    };
  };
}

const ImageMessageNode = ({ id, data }: ImageNodeProps) => {
  const { setNodes, deleteElements } = useReactFlow();
  const store = useStoreApi();
  const [form] = Form.useForm();
  const { updateFormErrors, deleteFormError } = useFormErrors();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [caption, setCaption] = useState(data.payload_info?.caption || '');
  
  useEffect(() => {
    if (data.payload_info) {
      setCaption(data.payload_info.caption || '');

      if (data.payload_info.url && data.payload_info.imageName) {
        setFileList([
          {
            uid: '-1',
            name: data.payload_info.imageName,
            status: 'done',
            url: data.payload_info.url,
          },
        ]);
      }
    }
  }, [data, form]);

  const updateNodeData = useCallback(
    (updatedPayload: any) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) =>
          node.id === id
              ? {
                ...node,
                data: { 
                  ...node.data, 
                  msg_type: 'ATTACHMENT',
                  payload_info: {
                    ...updatedPayload,
                    content_type: 'image'
                  }
                },
              }
              : node
        )
      );
    },
    [id, setNodes, store]
  );

  const handleMouseLeave = () => {
    form
      .validateFields()
      .then(() => {
  
        if (!fileList || fileList.length === 0) {
          updateFormErrors(id, true);
        } else {
          updateFormErrors(id, false);
          updateNodeData({
            ...data.payload_info,
            caption,
          });
        }
      })
      .catch(() => {
        updateFormErrors(id, true);
      });
  };
  
  

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(id);
    deleteElements({ nodes: [{ id }] });
  };

  const fileUploadCustomRequest = async (options: any) => {
    const { file, onSuccess } = options;
    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024;

    const allowedImageTypes = ['image/jpeg', 'image/png'];
    if (!allowedImageTypes.includes(fileType)) {
      showErrorNotification('Invalid file type');
      return;
    }

    if (fileSize > 5) {
      showErrorNotification('Image size is too large');
      return;
    }

    setUploadingFile(true);

    try {
      const response = await uploadChatbotMediaFile(file);

      if (response.id !== 'None') {
        const url = response.id;
        onSuccess(response, file);

        updateNodeData({
          ...data.payload_info,
          url,
          imageName: file.name,
        });

        setUploadingFile(false);
      } else {
        message.error(`${file.name} file type is not supported.`);
        setUploadingFile(false);
      }
    } catch (error) {
      showErrorNotification('File upload failed. Please try again.');
      setUploadingFile(false);
    }
  };

  const onChangeFile = (info: any) => {
    setFileList(info.fileList);
    const { status } = info.file;

    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      setFileList([]);
      updateNodeData({
        ...data.payload_info,
        url: undefined,
        imageName: undefined,
      });
    } else if (status === 'removed') {
      setFileList([]);
      updateNodeData({
        ...data.payload_info,
        url: undefined,
        imageName: undefined,
      });
    }
  };

    const handleCaptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCaption(e.target.value);
    };
  return (
      <div style={{ position: 'relative' }}>
        <Handle type="target" position={Position.Left} className="edgebutton-handle" />
        <Tooltip
            color="white"
            title={
              <Button
                  title="Delete"
                  onClick={onDeleteNode}
                  icon={<DeleteOutlined />}
                  style={{ height: '35px', width: '35px' }}
              />
            }
            placement="rightTop"
        >
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Card title="Image Message" style={{ width: '400px', border: "1px solid #173409FF" }} onMouseLeave={handleMouseLeave}>
              <Form form={form} name="image_form">
                <Form.Item
                    label={<><span style={{ color: 'red', fontSize: '16px',marginRight:5}}>*</span> Upload Image</>}
                    name="image"
                >
                  <Upload
                      name="file"
                      accept=".jpeg,.png"
                      maxCount={1}
                      customRequest={fileUploadCustomRequest}
                      onChange={onChangeFile}
                      fileList={fileList}
                      showUploadList={{
                        showRemoveIcon: true,
                        removeIcon: <DeleteOutlined />,
                      }}
                  >
                    <Button type="default" className="bg-gray-lightest mx-2 px-5 w-100" loading={uploadingFile}>
                      <CloudUploadOutlined /> Upload
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item name="caption">
                  <Input.TextArea 
                  placeholder="Add a caption for your image" 
                  autoSize={{ minRows: 2 }}
                  value={caption}
                  onChange={handleCaptionChange}
                   />
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Tooltip>
        <Handle type="source" position={Position.Right} className="edgebutton-handle" />
      </div>
  );
};

export default ImageMessageNode;