import React, { useEffect, useState } from "react";
import { Handle, Position, useReactFlow, useStoreApi } from "reactflow";
import {
  Button,
  Card,
  Form,
  Input,
  message as antdMessage,
  Modal,
  Tooltip,
  Typography,
  Row,
  Col,
  Collapse,
  Radio,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  TextListNodePayload,
  TextAndListNodeProps,
} from "models/chatbot";
import useFormErrors from "hooks/useFormErrors";
import { generateUniqueId } from "utils/helper";

const { Text } = Typography;
const { Panel } = Collapse;

const InteractiveListNode = (selectedNode: TextAndListNodeProps) => {
  const { setNodes } = useReactFlow();
  const store = useStoreApi();
  const { deleteElements } = useReactFlow();
  const [form] = Form.useForm();
  const { id: selectedNodeId } = selectedNode;
  const [listForm] = Form.useForm();
  const { updateFormErrors, deleteFormError } = useFormErrors();
  const [sections, setSections] = useState<any[]>([]);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newRow, setNewRow] = useState({ title: "", description: "" });


  useEffect(() => {
    const {
      data: { payload_info: payloadInfo },
    } = selectedNode;
    if (payloadInfo) {
      form.setFieldsValue({
        header: payloadInfo.header,
        body: payloadInfo.body,
        footer: payloadInfo.footer,
        button_text: payloadInfo.button_text,
      });
      if (payloadInfo.sections) {
        const updatedSections = payloadInfo.sections?.map((section: any) => ({
          id: section.id,
          title: section.title,
          rows: section.rows,
        }));
        setSections(updatedSections);
      }
      if (payloadInfo.header && typeof payloadInfo.header === "string") {
        form.setFieldsValue({ header: payloadInfo.header });
      }
    }
  }, []);

  useEffect(() => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === selectedNodeId) {
          // eslint-disable-next-line no-param-reassign
          node.data = {
            ...node.data,
            payload_info: {
              ...node.data.payload_info,
              button_text: form.getFieldValue("button_text") || "",
              sections: sections.map((section) => ({
                id: section.id,
                title: section.title,
                rows: section.rows,
              })),
            },
          };
        }
        return node;
      })
    );
  }, [sections]);

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(selectedNodeId);
    deleteElements({ nodes: [{ id: selectedNodeId }] });
  };

  const handleMouseLeave = () => {
    form
      .validateFields()
      .then((values) => {
        if (sections.length < 1) {
        //  antdMessage.error("At least 1 section is required.");
          return;
        }
        const totalRows = sections.reduce(
          (acc, section) => acc + section.rows.length,
          0
        );
        if (totalRows > 10) {
         // antdMessage.error("Maximum of 10 rows allowed across all sections.");
          return;
        }

        if (!values.button_text || values.button_text.trim() === "") {
          // antdMessage.error("Button text is required.");
          return;
        }

        const updatedPayload: TextListNodePayload = {
          header: values.header,
          body: values.body,
          footer: values.footer,
          button_text: values.button_text,
          sections: sections?.map((section: any) => ({
            id: section.id,
            title: section.title,
            rows: section.rows,
          })),
        };

        const { nodeInternals } = store.getState();
        setNodes(
          Array.from(nodeInternals.values()).map((node) => {
            if (node.id === selectedNodeId) {
              return {
                ...node,
                data: {
                  ...node.data,
                  msg_type: values.msg_type,
                  payload_info: updatedPayload,
                },
              };
            }
            return node;
          })
        );
      })
      .catch((errorInfo: any) => {
        updateFormErrors(selectedNodeId, true);
      });

    listForm
      .validateFields()
      .then(() => {
        updateFormErrors(selectedNodeId, false);
      })
      .catch(() => {
        updateFormErrors(selectedNodeId, true);
      });
  };

  const handleModalSubmit = () => {
    const totalRows = sections.reduce(
      (acc, section) => acc + section.rows.length,
      0
    );
    if (totalRows >= 10) {
      antdMessage.error("Maximum of 10 rows allowed across all sections.");
      return;
    }
    if (newRow.title) {
      const updatedSections = sections.map((section: any) => {
        if (section.id === selectedSection) {
          return {
            ...section,
            rows: [...section.rows, { id: generateUniqueId(), ...newRow }],
          };
        }
        return section;
      });
      setSections(updatedSections);
      setIsModalVisible(false);
      setNewRow({ title: "", description: "" });
    } else {
      antdMessage.error(`Title is required`);
    }
  };

  const handleDeleteRow = (sectionId: string, rowId: string) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          rows: section.rows.filter((row: any) => row.id !== rowId),
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const handleDeleteSection = (sectionId: string) => {
    const updatedSections = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSections);
  };

  const handleAddSection = () => {
    if (sections.length >= 10) {
      antdMessage.error("Maximum of 10 sections allowed.");
      return;
    }
    const hasEmptyTitle = sections.some(
      (section) => section.title.trim() === ""
    );
    if (hasEmptyTitle) {
      antdMessage.error(
        "Please fill the section titles before adding a new section."
      );
      return;
    }
    const newSection = {
      id: generateUniqueId(),
      title: "",
      rows: [],
    };
    setSections([...sections, newSection]);
  };

  const handleSectionSelect = (sectionId: string) => {
    setSelectedSection(sectionId);
  };


  return (
    <div style={{ position: "relative" }}>
      <Tooltip
        color="white"
        title={
          <div
            style={{
              background: "transparent",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              title="Delete"
              onClick={(event) => onDeleteNode(event)}
              icon={<DeleteOutlined />}
              style={{ height: "35px", width: "35px" }}
            />
          </div>
        }
        placement="rightTop"
      >
        <Handle
          type="target"
          position={Position.Left}
          className="edgebutton-handle"
        />
        <div style={{ position: "relative", display: "inline-block" }}>
          <Card
            title="Interactive List Message"
            style={{ width: "380px", border: "1px solid #173409FF" }}
            onMouseLeave={handleMouseLeave}
          >
            <Form form={form} name="node_form" layout="vertical">
              <Form.Item name="msg_type" initialValue="INTERACTIVE_LIST" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name="header"
                label="Header Text"
                extra="Maximum length is 60 characters"
                rules={[
                  {
                    max: 60,
                    message: "Header text cannot exceed 60 characters",
                  },
                ]}
              >
                <Input placeholder="Enter Header Text" />
              </Form.Item>     

              <span style={{ color: "red", fontSize: "16px" }}>*</span>
              <Form.Item
                name="body"
                rules={[{ required: true, message: "Body is required" }]}
              >
                <Input.TextArea placeholder="Body" autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item name="footer">
                <Input placeholder="Footer" />
              </Form.Item>
              <Form.Item
                name="button_text"
                rules={[
                  { required: true, message: "Button text is required" },
                  {
                    max: 20,
                    message: "Button text cannot exceed 20 characters",
                  },
                ]}
              >
                <Input
                  prefix={<MenuOutlined />}
                  placeholder="Enter Button Text"
                />
              </Form.Item>
              <Form.Item>
                <Row gutter={8} align="middle">
                  <Col span={18}>
                    <h4 style={{ margin: 0 }}>Section</h4>
                  </Col>
                  <Col
                    span={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={handleAddSection}
                    >Add </Button>
                  </Col>
                </Row>

                {sections.map((section: any) => (
                  <div
                    key={section.id}
                    style={{ marginTop: 10, marginBottom: 4 }}
                  >
                    <Row gutter={12} align="middle" style={{ marginBottom: 8 }}>
                      <Col span={2}>
                        <Radio
                          checked={selectedSection === section.id}
                          onChange={() => handleSectionSelect(section.id)}
                        />
                      </Col>
                      <Col span={18}>
                        <Input
                          size="small"
                          style={{ marginLeft: 2 }}
                          value={section.title}
                          onChange={(e) => {
                            const updatedSections = [...sections];
                            const sectionIndex = updatedSections.findIndex(
                              (s) => s.id === section.id
                            );
                            updatedSections[sectionIndex].title =
                              e.target.value;
                            setSections(updatedSections);
                          }}
                          placeholder="Enter section title"
                          maxLength={24}
                        />
                      </Col>
                      <Col span={4}>
                        {section.title && (
                            <Button
                              size="small"
                              icon={<DeleteOutlined />}
                              onClick={() => handleDeleteSection(section.id)}
                              danger
                            />                       
                        )}
                      </Col>
                    </Row>
                    <Row gutter={12} align="middle" style={{ marginBottom: 8 ,justifyContent:'center' }}>
                    <Col span={6}>
                        {section.title && (                       
                            <Button
                              size="small" type="dashed"
                              // icon={<PlusOutlined />}
                              onClick={() => {
                                setSelectedSection(section.id);
                                setIsModalVisible(true);
                              }}
                            >
                              Add List
                            </Button>                         
                            )}
                      </Col>
                    </Row>
                    {selectedSection === section.id && (
                      <Collapse accordion style={{ marginTop: "8px" }}>
                        {selectedSection === section.id &&
                          section.rows.map((row: any) => (
                            <Panel
                              key={row.id}
                              header={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <h5>{row.title}</h5>
                                  <Button
                                    type="link"
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                      handleDeleteRow(section.id, row.id)
                                    }
                                    danger
                                    size="small"
                                  />
                                </div>
                              }
                            >
                              <Text
                                type="secondary"
                                style={{ fontSize: "12px", display: "block" }}
                              >
                                {row.description}
                              </Text>
                            </Panel>
                          ))}
                      </Collapse>
                    )}
                  </div>
                ))}
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Tooltip>

      <Modal
        title="Add Row"
        visible={isModalVisible}
        onOk={handleModalSubmit}
        onCancel={() => {
          setIsModalVisible(false);
          setNewRow({ title: "", description: "" });
        }}
      >
        <Form layout="vertical">
          <Form.Item
            label={
              <>
                <span style={{ color: 'red', marginRight: 4 }}>*</span>
                Title
              </>
            }
            rules={[
              { required: true, message: "Title is required" },
              { max: 24, message: "Title cannot exceed 24 characters" },
            ]}
          >
            <Input
              value={newRow.title}
              onChange={(e) => setNewRow({ ...newRow, title: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[
              { max: 72, message: "Description cannot exceed 72 characters" },
            ]}
          >
            <Input.TextArea
              value={newRow.description}
              onChange={(e) =>
                setNewRow({ ...newRow, description: e.target.value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default InteractiveListNode;
