import {
  WhatsappMetaOnboardRequest,
  WhatsappTemplateCreateRequest,
  WhatsappTemplateDeleteRequest,
  WhatsappTemplateUpdateRequest,
  WhatsappSettingsUpdateRequest,
  WhatsappOriginatorRegisterRequest,
} from "../../models/requests";

import {
  WhatsappMetaBusinessProfile,
  WhatsappMetaContactList,
  WhatsappMetaDisplayNameEdit,
  WhatsappMetaWabaList,
} from "../../models";

import {
  FileResumableUploadResponse,
  MediaUploadResponse,
  WhatsappMetaTemplateCreateResponse,
  WhatsappTemplateDeleteResponse,
  WhatsappTemplateListResponse,
  WhatsappTemplateUpdateResponse,
  WhatsappOnboardingResponse,
  WhatsappResponse,
  WhatsappSettingsUpdateResponse,
  WhatsappContactListResponse,
  WhatsappSubscriptionResponse,
} from "../../models/responses";
import WhatsappMetaApi from "./index";

const saveWhatsappToken = async (data: {"token":string}) => WhatsappMetaApi.post<string, {"token":string}>('save-token', data);
const getOnboardingDetails = async () => WhatsappMetaApi.get<WhatsappOnboardingResponse>('meta-onboarding');

const listWhatsappMetaRegisteredNumbers = async () =>
  WhatsappMetaApi.get<WhatsappMetaContactList>(
    "meta-onboarding/onboarded-numbers"
  );
const getWhatsappMetaRegisteredNumbers = async (number: string) =>
  WhatsappMetaApi.get<WhatsappMetaContactList>(`meta-onboarding/${number}`);
const whatsappMetaOnboarding = async (data: WhatsappMetaOnboardRequest) =>
  WhatsappMetaApi.post<string, WhatsappMetaOnboardRequest>(
    "meta-onboarding",
    data
  );
const createWhatsappMetaTemplate = async (
  data: WhatsappTemplateCreateRequest
) =>
  WhatsappMetaApi.post<
    WhatsappMetaTemplateCreateResponse,
    WhatsappTemplateCreateRequest
  >("meta-template/create", data);
const updateWhatsappMetaTemplate = async (
  data: WhatsappTemplateUpdateRequest,
  templateId: number
) =>
  WhatsappMetaApi.put<
    WhatsappTemplateUpdateResponse,
    WhatsappTemplateUpdateRequest
  >(`meta-template/update/${templateId}`, data);

const listUtilityWhatsappTemplate = async () => WhatsappMetaApi.get<WhatsappTemplateListResponse>('meta-template/utility_template');
const listWhatsappMetaTemplate = async (wabaId: string) =>
  WhatsappMetaApi.get<WhatsappTemplateListResponse>(
    `meta-template?waba_id=${wabaId}`
  );
const listAllWhatsappTemplate = async () =>
  WhatsappMetaApi.get<WhatsappTemplateListResponse>(
    `meta-template/all`
  );
const getWhatsappTemplate = async (templateName:string, data: {"language": string}) => WhatsappMetaApi.get<boolean>(`meta-template/exist/${templateName}`, data);
const listWhatsappTemplateGettingStarted = async () => WhatsappMetaApi.get<WhatsappTemplateListResponse>('meta-template/getting-started');
const listWhatsappMetaMarketingTemplate = async (number: string) =>
  WhatsappMetaApi.get<WhatsappTemplateListResponse>(
    `meta-template/marketing_template/${number}`
  );
const listWhatsappMetaAuthenticationTemplate = async (number: string) =>
  WhatsappMetaApi.get<WhatsappTemplateListResponse>(
    `meta-template/authentication_template/${number}`
  );
const deleteWhatsappMetaTemplate = async (
  data: WhatsappTemplateDeleteRequest
) =>
  WhatsappMetaApi.delete<WhatsappTemplateDeleteResponse>(
    `meta-template/delete/${data.id}`
  );

const uploadResumableFile = async (data: any) =>
  WhatsappMetaApi.post<FileResumableUploadResponse>(
    "meta-template/uploads",
    data,
    false,
    {
      "Content-Type": "multipart/form-data",
    }
  );
const uploadMediaFileForChatbot = async (data: any) =>
    WhatsappMetaApi.post<MediaUploadResponse>(
      `media/upload/chatbot-media-file`,
      data,
      false,
      {
        "Content-Type": "multipart/form-data",
      }
    );
const listWhatsappMetaWabaId = async () =>
  WhatsappMetaApi.get<WhatsappMetaWabaList>("meta-numbers");

const getOriginator = async (originator?: string) =>
  WhatsappMetaApi.get<WhatsappMetaContactList>(
    `meta-onboarding/onboarded-numbers/${originator}`
  );

const uploadMediaFile = async (originator: any, data: any) =>
  WhatsappMetaApi.post<MediaUploadResponse>(
    `media/${originator}`,
    data,
    false,
    {
      "Content-Type": "multipart/form-data",
    }
  );

const getWhatsappSubscription = async ()=> WhatsappMetaApi.get<WhatsappSubscriptionResponse>('meta-subscription/user');

const getWhatsappMetaBusinessProfile = async (number: string) =>
  WhatsappMetaApi.get<WhatsappMetaBusinessProfile>(`meta-profile/${number}`);

const updateWhatsappMetaBusinessProfile = async (
  ContactNumber: string | undefined,
  data: WhatsappMetaBusinessProfile
) =>
  WhatsappMetaApi.post<
    WhatsappMetaBusinessProfile,
    WhatsappMetaBusinessProfile
  >(`meta-profile/update/${ContactNumber}`, data);


const updateWhatsappMetaDisplayName = async (
  ContactNumber: string | undefined,
  data: WhatsappMetaDisplayNameEdit
) =>
  WhatsappMetaApi.post<any, any>(
    `meta-profile/display_name_update/${ContactNumber}`,
    data
  );

const fetchWhatsappMediaFile = async (mediaId: string, onDownloadProgress: (progressEvent: ProgressEvent) => void) => WhatsappMetaApi.getBlobWithProgress(`media/${mediaId}`, onDownloadProgress);


const getWhatsappTemplateMessage = async (templateId: string, temLanguage: string) => WhatsappMetaApi.get("meta-template/get-template", {language: temLanguage, template_id: templateId});


const getWhatsappSettings = async () => WhatsappMetaApi.get<WhatsappResponse>('meta-setting');
const updateWhatsappSettings = async (data: WhatsappSettingsUpdateRequest) => WhatsappMetaApi.put<WhatsappSettingsUpdateResponse, WhatsappSettingsUpdateRequest>('meta-setting', data);
const sendWhatsappOriginatorRegisterRequest = async (data: WhatsappOriginatorRegisterRequest) => WhatsappMetaApi.post<string, WhatsappOriginatorRegisterRequest>('meta-setting/register-originator', data);
const listWhatsappContact = async () => WhatsappMetaApi.get<WhatsappContactListResponse>('meta-numbers/active');
export {
  createWhatsappMetaTemplate,
  deleteWhatsappMetaTemplate,
  getOriginator, getWhatsappMetaBusinessProfile,
  listWhatsappContact,
  getWhatsappMetaRegisteredNumbers,
  listWhatsappMetaAuthenticationTemplate,
  listWhatsappMetaMarketingTemplate,
  listWhatsappMetaRegisteredNumbers,
  listWhatsappMetaTemplate,
  listWhatsappMetaWabaId,
  getWhatsappTemplate,
  listAllWhatsappTemplate,
  listUtilityWhatsappTemplate,
  updateWhatsappMetaBusinessProfile,
  updateWhatsappMetaDisplayName,
  updateWhatsappMetaTemplate,
  uploadMediaFile,
  uploadResumableFile,
  whatsappMetaOnboarding,
  getOnboardingDetails,
  fetchWhatsappMediaFile,
  getWhatsappTemplateMessage,
  listWhatsappTemplateGettingStarted,
  getWhatsappSettings,
  updateWhatsappSettings,
  sendWhatsappOriginatorRegisterRequest,
  getWhatsappSubscription,
  saveWhatsappToken,
  uploadMediaFileForChatbot,
};

