import {
  InterveneInboxRequest,
  WhatsappAnalyticsStatusRequest,
  WhatsappAnalyticsUserInitiatedStatusRequest, WhatsappApiReportExportRequest,
  WhatsappApiReportFilterRequest
} from "../../models/requests";
import MessageReportApi from ".";
import {
  WhatsappApiReportExportResponse,
  WhatsappApiReportListResponse,
  WhatsappRecipientAnalyticsResponse
} from "../../models/responses";
import {WhatsappMetaChatList} from "../../models";
import {WhatsappChatExportResponse} from "../../models/responses/WhatsappChatExportResponse";

const getWhastappAnalyticsRecipientsService = async (data: WhatsappAnalyticsStatusRequest) => MessageReportApi.get<WhatsappRecipientAnalyticsResponse>("whatsapp-report/campaign-recipients", data) ;
const getWhastappAnalyticsRecipientsServiceUserInitiated = async (data: WhatsappAnalyticsUserInitiatedStatusRequest) => MessageReportApi.get<WhatsappRecipientAnalyticsResponse>("whatsapp-report/campaign-recipients-user-initiated", data) ;
const getWhatsappApiSearchResult = (key: string,data: WhatsappApiReportFilterRequest) => MessageReportApi.get<WhatsappApiReportListResponse,WhatsappApiReportFilterRequest>(`whatsapp-report/search/${key}`,data);
const getWhatsappApiFilterResult = (data: WhatsappApiReportFilterRequest ) => MessageReportApi.get<WhatsappApiReportListResponse, WhatsappApiReportFilterRequest>(`whatsapp-report/filter`, data);

const getWhatsappApiReportExport = (data: WhatsappApiReportExportRequest ) => MessageReportApi.get<WhatsappApiReportExportResponse, WhatsappApiReportExportRequest>(`whatsapp-report/export`,data);

const checkNumberIntervened = async (registeredNumber: string, customerNumber: string) => MessageReportApi.get<boolean>(`whatsapp-inbox/intervene-inbox/${registeredNumber}/${customerNumber}`);

const InterveneChat = async (data: InterveneInboxRequest) => MessageReportApi.post<string>('whatsapp-inbox/intervene-inbox', data);
const resumeChatbotInChat = async (registeredNumber: string, customerNumber: string) => MessageReportApi.delete(`whatsapp-inbox/end-intervene-inbox/${registeredNumber}/${customerNumber}`);
const listWhatsappChatsByPhoneNumber = async (number:string, pageNumber: number, pageSize:number, searchQuery:string) => MessageReportApi.get<WhatsappMetaChatList>('whatsapp-inbox', {phone_number: number, page: pageNumber, page_size: pageSize, search_key: searchQuery});

const listWhatsappChatMessagesByPhoneNumber = async (
  brandNumber: string,
  customerNumber: string,
  pageNumber: number,
  abortSignal?: AbortSignal
) =>
  MessageReportApi.getWithSignal<any>(
    `whatsapp-inbox/${customerNumber}`,
    { brand_number: brandNumber, page: pageNumber },
    false,
    {},
    abortSignal
  );

const getWhatsappChatExport = (brandNumber: string, customerNumber: string ) => MessageReportApi.get<WhatsappChatExportResponse>(`whatsapp-inbox/conversation_export/${customerNumber}/${brandNumber}`);


export {
  getWhatsappApiSearchResult,
  getWhatsappApiFilterResult,
  getWhatsappApiReportExport,
  getWhastappAnalyticsRecipientsService,
  getWhastappAnalyticsRecipientsServiceUserInitiated,
  listWhatsappChatMessagesByPhoneNumber,
  listWhatsappChatsByPhoneNumber,
  checkNumberIntervened,
  InterveneChat,
  resumeChatbotInChat,
  getWhatsappChatExport
};
