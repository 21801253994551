import {
  SMSMessageSendRequest,
  ViberMessageSendRequest,
  VerifyApiResendOTPRequest,
  VerifyApiSendOTPRequest,
  VerifyApiVerifyOTPRequest, NumberLookupRequest
} from 'models/requests';

import {
  reSendVerifyOtp,
  sendSMSMessage,
  sendVerifyOtp,
  sendViberMessage,
  verifyVerifyOtp,
  getNumberLookup,
  sendMetaWhatsappMessage
} from "api/externalMessage/IntegrationTestApi";

const sendSMSMessageService = async (data: SMSMessageSendRequest, token: string) => sendSMSMessage(data, token);
const sendViberMessageService = async (data: ViberMessageSendRequest, token: string) => sendViberMessage(data, token);
const reSendOtpService = async (data: VerifyApiResendOTPRequest, token: string) => reSendVerifyOtp(data, token);
const sendOtpService = async (data: VerifyApiSendOTPRequest, token: string) => sendVerifyOtp(data, token);
const verifyOtpService = async (data: VerifyApiVerifyOTPRequest, token: string) => verifyVerifyOtp(data, token);
const getNumberLookupService =async (data:NumberLookupRequest, token:string) => getNumberLookup(data, token);

const sendMetaWhatsappMessageService = async (data: any, token: string) => sendMetaWhatsappMessage(data, token);

export {
  sendSMSMessageService,
  sendViberMessageService,
  reSendOtpService,
  sendOtpService,
  verifyOtpService,
  getNumberLookupService,
  sendMetaWhatsappMessageService
};
