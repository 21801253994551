import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// import ManageWhatsappCampaign from "./ManageWhatsappCampaign";
// import WhatsappCampaignReport from "./WhatsappCampaignReport";
// import ConnectWhatsapp from "./WhatsappConnect";
// import WhatsappConnectSuccess from "./WhatsappConnect/Success";
// import WhatsappConnectFailure from "./WhatsappConnect/Failure";
import Conversation from "components/whatsapp-meta/WhatsappInbox/Conversation";
import ConversationEmpty from "components/whatsapp-meta/WhatsappInbox/ConversationEmpty";
import WhatsappConversationFlow from "../../components/WhatsappMetaConversationFlow";
import WhatsappCampaign from "./WhatsappCampaign";
import OnboardedNumberDetails from "../../components/whatsapp-meta/WhatsappSettings/OnboardedNumberDetails";
import Settings from "./Settings";
import WhatsappTemplate from "./WhatsappTemplate/index";
import ApiReport from "./ApiReport";
import RegisteredNumbers from "./Numbers";
import Chatbot from "./MetaChatBot";

const GettingStarted = lazy(() => import("./GettingStarted"));
const WhatsappCampaignTab = lazy(() => import("./WhatsappCampaignTab"));
// const WhatsappPricing = lazy (() => import("./Pricing"));
const WhatsappInbox = lazy(()=> import("./WhatsappInbox"));

const WhatsappMetaPages = () => (
  <Routes>
    <Route
      path="/"
      element={<Navigate to="/whatsapp-meta/connect-whatsapp" replace />}
    />
    {/* <Route path='connect-whatsapp/*'> */}
    {/*  <Route path="" element={<ConnectWhatsapp />} /> */}
    {/*  <Route path='success' element={<WhatsappConnectSuccess />} /> */}
    {/*  <Route path='failure' element={<WhatsappConnectFailure />} /> */}
    {/*  <Route path="*" element={<Navigate to="/error/404" replace />} /> */}
    {/* </Route> */}
    <Route path="getting-started" element={<GettingStarted />} />
    <Route path="whatsapp-campaign/*" element={<WhatsappCampaignTab />}>
      <Route path=":id" element={<WhatsappCampaign />} />
    </Route>
    <Route path='whatsapp-meta-template' element={<WhatsappTemplate />} />
    <Route path='api-report' element={<ApiReport />} />
    {/* <Route path='pricing' element={<WhatsappPricing />} /> */}
    <Route path='settings' element={<Settings />} />
    <Route path='registered-numbers' element={<RegisteredNumbers />} />
    <Route path='registered-numbers/:id' element={<OnboardedNumberDetails/>} />
    <Route path='meta-chatbot' element={<Chatbot/>}/>
    <Route path="meta-chatbot/:name/:id" element={<WhatsappConversationFlow/>}/>
    <Route path="inbox" element={<WhatsappInbox />}>
      <Route path="" element={<ConversationEmpty />} />
      <Route path=":id" element={<Conversation />} />
    </Route>
    <Route path="*" element={<Navigate to="/error/404" replace />} />
  </Routes>
);

export default WhatsappMetaPages;
