import React, { useEffect, useState, useCallback } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';

import { Avatar, Button, Card, Col, DatePicker, Form, Input, Modal, Popover, Row, Tooltip, message } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import useFormErrors from 'hooks/useFormErrors';
import moment from 'moment';
import { generateUniqueId } from 'utils/helper';


interface Address {
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  country_code?: string;
  type?: string;
  id?: string;
}

interface Phone {
  phone?: string;
  type?: string;
  id?: string;
}

interface Email {
  email?: string;
  id?: string;
}

interface Url {
  url?: string;
  id?: string;
}

interface ContactNodeProps {
  id: string;
  data: {
    msg_type: 'CONTACTS';
    payload_info:any[]
  };
}

const ContactMessageNode = ({ id, data }: ContactNodeProps) => {
  const { setNodes, deleteElements } = useReactFlow();
  const store = useStoreApi();
  const [form] = Form.useForm();
  const { deleteFormError } = useFormErrors();
  const [contacts, setContacts] = useState<any[]>(data.payload_info || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [phones, setPhones] = useState<Phone[]>([]);
  const [emails, setEmails] = useState<Email[]>([]);
  const [urls, setUrls] = useState<Url[]>([]);
  const [editingContact, setEditingContact] = useState<any>(null);
  const [currentAddress, setCurrentAddress] = useState<Address>({
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    country_code: "",
    type: "",
    id: ""
  });



const sectionHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '12px',
  marginTop: '24px'
};

const sectionTitleStyle = {
  margin: 0,
  fontSize: '16px',
  fontWeight: 600,
  color: 'rgba(0, 0, 0, 0.85)'
};

  useEffect(() => {
    setContacts(data.payload_info || []);
  }, [data]);

  const updateNodeData = useCallback(
    (updatedContacts: any[]) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) =>
          node.id === id
            ? {
                ...node,
                data: {
                  ...node.data,
                  msg_type: 'CONTACTS',
                  payload_info: updatedContacts,
                },
              }
            : node
        )
      );
    },
    [id, setNodes, store]
  );
  const resetFormData = () => {
    setAddresses([]);
    setEmails([]);
    setPhones([]);
    setUrls([]);
    setCurrentAddress({
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      country_code: "",
      type: "",
      id: ""
    });
    setEditingContact(null);
  };

  
  const handleAddContact = () => {
    form.validateFields().then((values) => {

      const invalidEmails = emails.filter(email => !/\S+@\S+\.\S+/.test(email.email || ''));
      if (invalidEmails.length > 0) {
        message.error('Please enter valid email addresses.');
        return;
      }

      const invalidUrls = urls.filter(url => !/^https?:\/\//.test(url.url || ''));
      if (invalidUrls.length > 0) {
        message.error('Please enter valid URLs starting with http:// or https://');
        return;
      }

      const invalidPhones = phones.filter(phone => !phone.phone || !/^[0-9]{10}$/.test(phone.phone));
      if (invalidPhones.length > 0) {
        message.error('Please enter valid phone numbers .');
        return;
      }

      const birthday = values.birthday ? moment(values.birthday).format('YYYY-MM-DD') : undefined;
      const name = {
        // formatted_name: values.formatted_name,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        suffix: values.suffix,
        prefix: values.prefix,
      };

      const newContact = {
        id: editingContact?.id || generateUniqueId(),
        name,
        birthday,
        org: values.org,
        addresses: addresses.map(addr => ({
          ...addr,
          id: addr.id || generateUniqueId()
        })),
        phones: phones.map(phone => ({
          ...phone,
          id: phone.id || generateUniqueId()
        })),
        emails: emails.map(email => ({
          ...email,
          id: email.id || generateUniqueId()
        })),
        urls: urls.map(url => ({
          ...url,
          id: url.id || generateUniqueId()
        })),
      };

      let newContacts;
      if (editingContact) {
        newContacts = contacts.map(contact => contact.id === editingContact.id ? newContact : contact);
      } else {
        newContacts = [...contacts, newContact];
      }
      setContacts(newContacts);
      updateNodeData(newContacts);
      setIsModalOpen(false);
      form.resetFields();
      resetFormData();
    }).catch(() => message.error("Please fill all required fields."));
  };



  const handleDeleteContact = (index: number) => {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    setContacts(updatedContacts);
    updateNodeData(updatedContacts);
  };

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(id);
    deleteElements({ nodes: [{ id }] });
  };


const handleAddAddress = () => {
  if (currentAddress.street && currentAddress.city && currentAddress.state) {
    const newAddress = {
      ...currentAddress,
      id: generateUniqueId()
    };
    
    setAddresses(prevAddresses => [...prevAddresses, newAddress]);
    
    setCurrentAddress({
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      country_code: "",
      type: "",
      id: ""
    });
  } else {
    message.error("Please fill all required address fields.");
  }
};



  const handleDeleteAddress = (index: number) => {
    setAddresses(addresses.filter((_, i) => i !== index));
  };

  const handleAddressChange = (field: keyof Address, value: string) => {
    setCurrentAddress(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAddPhone = () => {
    setPhones([...phones, { phone: '', type: '', id: generateUniqueId() }]);
  };

  const handlePhoneChange = (index: number, field: keyof Phone, value: string) => {

    const phoneRegex = /^[0-9]*$/;
    if (field === 'phone') {
      if (phoneRegex.test(value) || value === '') {
    const updatedPhones = [...phones];
    updatedPhones[index] = {
      ...updatedPhones[index],
      [field]: value
    };
    setPhones(updatedPhones);
    }
  }
  };

  const handleAddEmail = () => {
    setEmails([...emails, { email: '', id: generateUniqueId() }]);
  };

  const handleEmailChange = (index: number, value: string) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = {
      ...updatedEmails[index],
      email: value
    };
    setEmails(updatedEmails);
  };

  const handleAddUrl = () => {
    setUrls([...urls, { url: '', id: generateUniqueId() }]);
  };

  const handleUrlChange = (index: number, value: string) => {
    const updatedUrls = [...urls];
    updatedUrls[index] = {
      ...updatedUrls[index],
      url: value
    };
    setUrls(updatedUrls);
  };

  const handleEditContact = (contact: any) => {
    setEditingContact(contact);
    form.setFieldsValue({
      // formatted_name: contact.name?.formatted_name,
      first_name: contact.name?.first_name,
      middle_name: contact.name?.middle_name,
      last_name: contact.name?.last_name,
      suffix: contact.name?.suffix,
      prefix: contact.name?.prefix,
      birthday: contact.birthday ? moment(contact.birthday) : undefined,
      org: contact.org,
    });
  
    setAddresses(contact.addresses || []);
 
      setCurrentAddress({
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        country_code: "",
        type: "",
        id: ""
      });

  
    setPhones(contact.phones || []);
    setEmails(contact.emails || []);
    setUrls(contact.urls || []);
    setIsModalOpen(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Handle type="target" position={Position.Left} className="edgebutton-handle" />
      <Tooltip
        color="white"
        title={
          <Button
            title="Delete"
            onClick={onDeleteNode}
            icon={<DeleteOutlined />}
            style={{ height: '35px', width: '35px' }}
          />
        }
        placement="rightTop"
      >
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Card
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Contact Message</span>
                <Button icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>Add</Button>
              </div>
            }
            style={{ width: '400px', border: "1px solid #173409FF" }}
          >
            <Row gutter={16}> 
        {contacts.map((contact, index) => (
          <Col xs={8} sm={8} md={8} lg={8} key={contact.id || index}>
            <Card size="small" style={{ marginBottom: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Popover
                  content={<div>
                            <EditOutlined 
                              onClick={() => handleEditContact(contact)} 
                              style={{ color: 'blue', cursor: 'pointer', marginRight: '8px' }} 
                            />
                            <DeleteOutlined 
                              onClick={() => handleDeleteContact(index)} 
                              style={{ color: 'red', cursor: 'pointer' }} 
                            />
                          </div>}
                  trigger="hover"
                >
                  <Avatar size={64} style={{ backgroundColor: '#87d068' }}>
                    {contact.name?.first_name?.[0].toUpperCase()}
                  </Avatar>
                </Popover>
                <span style={{ marginTop: '8px', textAlign: 'center' }}>
                   {contact?.name?.first_name } {contact?.name?.last_name}</span>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
          </Card>
        </div>
      </Tooltip>
      <Handle type="source" position={Position.Right} className="edgebutton-handle" />

      <Modal
        title="Add Contact"
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          form.resetFields();
          resetFormData();
        }}
        onOk={handleAddContact}
        width={700}
      >
        <Form form={form} layout="vertical">
          <h4>Name</h4>
          {/* <Form.Item name="formatted_name" label="Formatted Name" rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item> */}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="first_name" label="First Name" rules={[{ required: true, message: 'Required' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="middle_name" label="Middle Name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="last_name" label="Last Name">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="suffix" label="Suffix">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="prefix" label="Prefix">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="birthday" label="Birthday">
            <DatePicker
              disabledDate={(current) => current && current > moment().endOf('day')}
              format="YYYY-MM-DD"
              style={{ width: '100%' }}
            />
          </Form.Item>

          <h4>Organization</h4>
          <Form.Item name={['org', 'company']} label="Company">
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={['org', 'department']} label="Department">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['org', 'title']} label="Title">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <div style={sectionHeaderStyle}>
            <h4 style={sectionTitleStyle}>Address</h4>
            <Button 
              size="small" 
              onClick={handleAddAddress}  
              icon={<PlusOutlined />}       
            >
              Add
            </Button>
          </div>
          {addresses.map((address, index) => (
            <Card key={address.id || index} size="small" style={{ marginBottom: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span>{address.street}, {address.city}, {address.state}</span>
                <DeleteOutlined onClick={() => handleDeleteAddress(index)} style={{ color: 'red', cursor: 'pointer' }} />
              </div>
            </Card>
          ))}
          
          <Row gutter={16} style={{ marginBottom: '8px' }}>
            <Col span={12}>
              <Input
                placeholder="Street"
                value={currentAddress.street}
                onChange={(e) => handleAddressChange('street', e.target.value)}
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder="City"
                value={currentAddress.city}
                onChange={(e) => handleAddressChange('city', e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '8px' }}>
            <Col span={12}>
              <Input
                placeholder="State"
                value={currentAddress.state}
                onChange={(e) => handleAddressChange('state', e.target.value)}
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder="Zip"
                value={currentAddress.zip}
                onChange={(e) => handleAddressChange('zip', e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '16px' }}>
            <Col span={12}>
              <Input
                placeholder="Country"
                value={currentAddress.country}
                onChange={(e) => handleAddressChange('country', e.target.value)}
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder="Country Code"
                value={currentAddress.country_code}
                onChange={(e) => handleAddressChange('country_code', e.target.value)}
              />
            </Col>
          </Row>
          
          <div style={sectionHeaderStyle}>
            <h4 style={sectionTitleStyle}>Phones</h4>
            <Button 
              size="small" 
              onClick={handleAddPhone}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </div>
          {phones.map((phone, index) => (
            <Row key={phone.id || index} gutter={16} style={{ marginBottom: '8px' }}>
              <Col span={20}>
                <Input
                  value={phone.phone}
                  placeholder="Phone"
                  onChange={(e) => handlePhoneChange(index, 'phone', e.target.value)}
                />
              </Col>
              <Col span={4}>
                <DeleteOutlined 
                  onClick={() => setPhones(phones.filter((_, i) => i !== index))}
                  style={{ color: 'red', cursor: 'pointer', marginTop: '8px' }}
                />
              </Col>
            </Row>
          ))}
          
          <div style={sectionHeaderStyle}>
            <h4 style={sectionTitleStyle}>Emails</h4>
            <Button 
              size="small" 
              onClick={handleAddEmail}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </div>
          {emails.map((email, index) => (
            <Row key={email.id || index} gutter={16} style={{ marginBottom: '8px' }}>
              <Col span={20}>
                <Input
                  value={email.email}
                  type='email'
                  placeholder="Email"
                  onBlur={() => {
                    if (!/\S+@\S+\.\S+/.test(email.email??'')) {
                      message.error('Please enter a valid email address');
                    }
                  }}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                />
              </Col>
              <Col span={4}>
                <DeleteOutlined 
                  onClick={() => setEmails(emails.filter((_, i) => i !== index))}
                  style={{ color: 'red', cursor: 'pointer', marginTop: '8px' }}
                />
              </Col>
            </Row>
          ))}
          
          <div style={sectionHeaderStyle}>
            <h4 style={sectionTitleStyle}>URLs</h4>
            <Button 
              size="small" 
              onClick={handleAddUrl}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </div>
          {urls.map((url, index) => (
            <Row key={url.id || index} gutter={16} style={{ marginBottom: '8px' }}>
              <Col span={20}>
                <Input
                  value={url.url}
                  placeholder="URL"
                  onBlur={() => {
                    if (!/^https?:\/\//.test(url.url??'')) {
                      message.error('Please enter a valid URL starting with http:// or https://');
                    }
                  }}
                  onChange={(e) => handleUrlChange(index, e.target.value)}
                />
              </Col>
              <Col span={4}>
                <DeleteOutlined 
                  onClick={() => setUrls(urls.filter((_, i) => i !== index))}
                  style={{ color: 'red', cursor: 'pointer', marginTop: '8px' }}
                />
              </Col>
            </Row>
          ))}
        </Form>
      </Modal>
    </div>
  );
};

export default ContactMessageNode;