import { RightCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React, { useState } from 'react';
import Avatar from 'react-avatar';

const { Title, Text } = Typography;

interface ContactMessageProps {
  contact: {
    contacts: {
      name: {
        last_name: string;
        first_name: string;
        formatted_name: string;
      };
      phones: {
        type: string;
        phone: string;
        wa_id: string;
      }[];
    }[];
  };
}

const WhatsappContactMessage: React.FC<ContactMessageProps> = ({ contact }) => {
  const [selectedContactIndex, setSelectedContactIndex] = useState(0);

  const contactInfo = contact?.contacts?.[selectedContactIndex];
  const phoneInfo = contactInfo?.phones?.[0];

  const handleNextContact = () => {
    setSelectedContactIndex((prevIndex) =>
      prevIndex === contact.contacts.length - 1 ? 0 : prevIndex + 1
    );
  };


  return (
    <div style={{ width:"270px", borderRadius: '4px', background: '#f0f0f0', padding: "10px", display: "flex"}}>
      <Row gutter={16} align="middle">
        <Col>
          <Avatar name={contactInfo?.name?.formatted_name} size="45" textSizeRatio={1.75} round/>
        </Col>
        <Col style={{flex:1}}>
          <Title level={4} style={{marginBottom:"0px"}}>{contactInfo?.name?.formatted_name ?? "Unavailable"}</Title>
          <Text type="secondary" style={{marginBottom:"0px", fontSize:"0.8rem"}}>{phoneInfo?.phone ?? "Unavailable"}</Text>
        </Col>
        { contact?.contacts?.length > 1 && (
        <Col>
          <Button
            type="link"
            icon={<RightCircleOutlined />}
            onClick={handleNextContact}
            style={{ fontSize: '24px', padding: 0 }}
          />
        </Col>
        )}
      </Row>
      
    </div>
  );
};

export default WhatsappContactMessage;
