import AxiosService from "api";

const MessageReportApi = {
  baseUrl: '/report/v1/',
  getUrl: (url: string) => `${MessageReportApi.baseUrl}${url}`,
  post: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.post<R, D>(MessageReportApi.getUrl(url), data, isPublic, headers),
  postWithQueryParams: <R = any, P = any, D = any>(url: string, queryData?: P, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.postWithQueryParams<R, P, D>(MessageReportApi.getUrl(url), queryData, data, isPublic, headers),
  put: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.put<R, D>(MessageReportApi.getUrl(url), data, isPublic, headers),
  get: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.get<R, D>(MessageReportApi.getUrl(url), data, isPublic, headers),
  getWithSignal: <R = any, D = any>(url: string, data?: D, isPublic?: boolean, headers?: any, signal?: AbortSignal) => AxiosService.getWithSignal<R, D>(MessageReportApi.getUrl(url), data, isPublic, headers, signal),
  delete: <R = any, D = any>(url: string, data?: D, isPublic: boolean = false, headers: any = {}) => AxiosService.delete<R, D>(MessageReportApi.getUrl(url), data, isPublic, headers),
};
export default MessageReportApi;
