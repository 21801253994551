import React, {useEffect, useState} from 'react';
import {Handle, Position, useReactFlow, useStoreApi} from 'reactflow';
import {Button, Card, Col, Dropdown, Form, Input, 
  message as antdMessage,
  Menu, Row, Tooltip, Upload} from "antd";
import {CloudUploadOutlined, DeleteOutlined, InfoCircleOutlined} from "@ant-design/icons";
import { buttonInfo, HeaderData, TextAndButtonNodeProps } from 'models/chatbot';
import useFormErrors from 'hooks/useFormErrors';
import { uploadChatbotMediaFile } from 'services/dashboard/WhatsappService';
import { showErrorNotification } from 'utils/helper';

const InteractiveButtonNode = (selectedNode: TextAndButtonNodeProps) => {
  const {setNodes, getEdges, setEdges} = useReactFlow();
  const store = useStoreApi();
  const [buttonNames, setButtonNames] = useState<buttonInfo[]>([]);
  const [headerType, setHeaderType] = useState<string | undefined>(undefined);
  const [headerFormat, setHeaderFormat] = useState<string | undefined>(undefined);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const {deleteElements} = useReactFlow();
  const [form] = Form.useForm();
  const {id: selectedNodeId} = selectedNode;
  const [buttonForm] = Form.useForm();
  const {updateFormErrors, deleteFormError} = useFormErrors();

  useEffect(() => {
    const {data: {payload_info: payloadInfo}} = selectedNode;
    if (payloadInfo) {

      form.setFieldsValue({header: payloadInfo.header, body: payloadInfo.body, footer: payloadInfo.footer});

      if (payloadInfo.buttons) {
        setButtonNames(payloadInfo.buttons);
      }
      if (payloadInfo.header ) {
          if (typeof payloadInfo.header === 'object'){
              const headerData = payloadInfo.header as HeaderData; 
              if (headerData.type === "text") {
                setHeaderType("TEXT");
                form.setFieldsValue({ header: headerData.text });
              } else if (headerData.type === "image") {
                setHeaderType("MEDIA");
                setHeaderFormat("IMAGE");
                // @ts-ignore
                form.setFieldsValue({ link: headerData.image.link });
                setFileList([{
                  uid: '-1',
                  // @ts-ignore
                  name: headerData.image.link.split('/').pop() || 'image',
                  // @ts-ignore
                  url: headerData.image.link,
                  status: 'done',
                }]);
              } else if (headerData.type === "video") {
                setHeaderType("MEDIA");
                setHeaderFormat("VIDEO");
                 // @ts-ignore
                form.setFieldsValue({ link: headerData.video.link });
                setFileList([{
                  uid: '-1',
                  // @ts-ignore
                  name: headerData.video.link.split('/').pop() || 'video',
                  status: 'done',
                  // @ts-ignore
                  url: headerData.video.link,
                }]);
              } else if (headerData.type === "document") {
                setHeaderType("MEDIA");
                setHeaderFormat("DOCUMENT");
                 // @ts-ignore
                form.setFieldsValue({ link: headerData.document.link });
                 // @ts-ignore
                setFileName(headerData.document.filename);
                setFileList([{
                  uid: '-1',
                  // @ts-ignore
                  name: headerData.document.filename,
                  status: 'done',
                  // @ts-ignore
                  url: headerData.document.link,
                }]);
              }
            } else if (typeof payloadInfo.header === 'string') {
              setHeaderType("TEXT");
              form.setFieldsValue({ header: payloadInfo.header });
            }
         }
    }
  }, []);
  
  useEffect(() => {
    const {nodeInternals} = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === selectedNodeId) {
          // eslint-disable-next-line no-param-reassign
          node.data = {
            ...node.data,
            payload_info: {
              ...node.data.payload_info,
              buttons: buttonNames.map((button) => ({
                id: button.id,
                name: button.name,
              })),
            }
          };
        }
        return node;
      })
    );
  }, [buttonNames]);

  const addInput = () => {
    const uniqueId = `${+new Date()}`;
    setButtonNames([...buttonNames, {id: uniqueId, name: ''}]);
  };

  const handleInputChange = (id: string, value: any) => {
    const updatedButtons = buttonNames.map((button) => {
      if (button.id === id) {
        return {...button, name: value};
      }
      return button;
    });
    setButtonNames(updatedButtons);
  };
  const deleteInput = (id: string) => {
    const connectedEdge = getEdges().find(
      (edge) => edge.sourceHandle === id
    );
    if (connectedEdge) {
      setEdges((eds) => eds.filter((e) => e.id !== connectedEdge.id));
    }
    const updatedButtons = buttonNames.filter((button) => button.id !== id);
    setButtonNames(updatedButtons);
  };

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(selectedNodeId);
    deleteElements({nodes: [{id: selectedNodeId}]});
  };
  const handleMouseLeave = () => {
    form.validateFields().then((values) => {
      let headerData;
      if (headerType === "TEXT") {
        headerData = { type: "text", text: values.header };
      } else if (headerFormat === "IMAGE") {
        headerData = { type: "image", image: { link: values.link } };
      } else if (headerFormat === "VIDEO") {
        headerData = { type: "video", video: { link: values.link } };
      } else if (headerFormat === "DOCUMENT") {
        headerData = {
          type: "document",
          document: { filename: fileName, link: values.link },
        };
      }
  
      const updatedPayload = {
        header: headerData,
        body: values.body,
        footer: values.footer,
        buttons: buttonNames.map((button) => ({
          id: button.id,
          name: button.name,
        })),
      };
  
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === selectedNodeId) {
            return {
              ...node,
              data: { ...node.data, msg_type: values.msg_type, payload_info: updatedPayload },
            };
          }
          return node;
        })
      );
    })
    .catch((errorInfo: any) => {
      updateFormErrors(selectedNodeId, true);
    });
  buttonForm.validateFields().then((values) => {
    updateFormErrors(selectedNodeId, false);
  }).catch((error) => {
    updateFormErrors(selectedNodeId, true);
  });
  };
  

  const handleHeaderTypeChange = ({ key }: { key: string }) => {
    if (key === "TEXT") {
      setHeaderType("TEXT");
      setHeaderFormat(undefined);
      form.setFieldsValue({ header: "", link: "" }); 
    } else if (["IMAGE", "VIDEO", "DOCUMENT"].includes(key)) {
      setHeaderType("MEDIA");
      setHeaderFormat(key);
      form.setFieldsValue({ header: "", link: "" }); 
    }
  };


  const fileUploadCustomRequest = (options: any) => {
    const isMedia = options.file.type;
    if (isMedia === "application/pdf" || isMedia === "image/jpeg" || isMedia === "image/png" || isMedia === "video/mp4" ||
       isMedia === "application/vnd.ms-excel" || isMedia === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    isMedia === "application/msword" || isMedia === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    isMedia === "application/vnd.ms-powerpoint" || isMedia === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
    isMedia === "video/3gp" || isMedia === "text/plain") {
      setUploadingFile(true);
      const data = new FormData();
      data.append('file', options.file);
      uploadChatbotMediaFile(options.file)
        .then(response => {
            if (response.id !== "None") {
              options.onSuccess(response, options.file);
            } else {
              antdMessage.error(`${options.file} file type is not supported.`);
            }
          }
        ).catch(error => {
        showErrorNotification(error.message);
        setUploadingFile(false);
        setFileList([]);
        form.setFieldsValue({link: ''});
      })
        .finally(() => setUploadingFile(false));
    } else {
      antdMessage.error(`${options.file} file type is not supported.`);
    }
  };

  const onChangeFile = (info: any) => {
    
    const {status, response} = info.file;
    setFileList(info.fileList);
    if (status === "done") {
      setFileName(info.file.name);
      antdMessage.success(`${info.file.name} file uploaded successfully.`);
      form.setFieldsValue({link: response.id});
    } else if (status === "error") {
      setFileList([]);
      form.setFieldsValue({link: ''});
      setFileName(undefined);
      antdMessage.error(`${info.file.name} file upload failed.`);
    } else if (status === "removed") {
      setFileList([]);
      form.setFieldsValue({link: ''});
      setFileName(undefined);
    }
  };

  const menu = (
    <Menu onClick={handleHeaderTypeChange}>
    <Menu.Item key="TEXT">Text</Menu.Item>
    <Menu.SubMenu key="MEDIA" title="Media">
      <Menu.Item key="IMAGE">Image</Menu.Item>
      <Menu.Item key="VIDEO">Video</Menu.Item>
      <Menu.Item key="DOCUMENT">Document</Menu.Item>
    </Menu.SubMenu>
  </Menu>
  );
  
  
  return (
    <div style={{ position: 'relative' }}>
    <Tooltip
      color='white'
      title={
        <div
          style={{
            background: 'transparent',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            title='Delete'
            onClick={(event) => onDeleteNode(event)}
            icon={<DeleteOutlined />}
            style={{ height: '35px', width: '35px' }}
          />
        </div>
      }
      placement='rightTop'
    >
      <Handle type='target' position={Position.Left} className='edgebutton-handle' />
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <Card
          title='Interactive Button Message'
          style={{ width: '350px', border: '1px solid #173409FF' }}
          onMouseLeave={handleMouseLeave}
        >
          <Form form={form} name='node_form' layout='vertical'>
          <Form.Item name='msg_type' initialValue='INTERACTIVE_BUTTON' hidden>
            <Input />
          </Form.Item>
            <Form.Item name='header_type' label='Header Type'>
              <Dropdown overlay={menu} trigger={['click']} getPopupContainer={() => document.body}>
                <Button  size='middle' >             
                {// eslint-disable-next-line no-nested-ternary
                 headerType === 'TEXT' ? 'Text' :headerFormat === 'IMAGE' ? 'Image' :headerFormat === 'VIDEO' ? 'Video' :
                  headerFormat === 'DOCUMENT' ? 'Document' : 'Select Header'
                 }
                </Button>
              </Dropdown>
            </Form.Item>

            {headerType === 'TEXT' && (
              <Form.Item name='header' label='Header Text'
              extra="Maximum length is 60 chars"
            rules={[
              {
                max: 60,
                message: 'Header text cannot exceed 60 characters',
              },
              {
                required: true,
                message: 'Please enter Header text',
              },]}
              >
                <Input placeholder='Enter Header Text' />
              </Form.Item>
            )}
  
            {headerType === 'MEDIA' && ['IMAGE', 'VIDEO', 'DOCUMENT'].includes(headerFormat || '') && (
              <Form.Item
                label={
                  <span>
                    Upload sample file{' '}
                    <Tooltip title={`Upload a sample ${headerFormat} file`}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name='link'
                rules={[{ required: true, message: `Please upload a sample ${headerFormat} file` }]}
              >
                <Upload
                  name='file'
                   // eslint-disable-next-line no-nested-ternary
                  accept={headerFormat === 'IMAGE'? '.jpeg, .png' : headerFormat === 'VIDEO'? '.mp4, .3gp': '.pdf, .txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx'
                  }
                  maxCount={1}
                  fileList={fileList}
                  customRequest={fileUploadCustomRequest}
                  onChange={onChangeFile}
                  showUploadList={{
                    showRemoveIcon: true,
                    removeIcon: <DeleteOutlined />,
                  }}
                >
                  <Button type='default' className='bg-gray-lightest mx-2 px-5 w-100' loading={uploadingFile}>
                    <CloudUploadOutlined /> Upload
                  </Button>
                </Upload>
              </Form.Item>
            )}
  
            <span style={{ color: 'red', fontSize: '16px' }}>*</span>
            <Form.Item name='body' rules={[{ required: true, message: 'Body is required' }]}> 
              <Input.TextArea placeholder='Body' autoSize={{ minRows: 3 }} />
            </Form.Item>
            <Form.Item name='footer'>
              <Input placeholder='Footer' />
            </Form.Item>
          </Form>
  
          <Form form={buttonForm} name='button_form'>
            {buttonNames.map((button) => (
              <Form.Item
                key={button.id}
                name={button.id}
                initialValue={button.name}
                rules={[{
                  validator: (_, value) =>
                    value && value.trim() !== '' ? Promise.resolve() : Promise.reject(new Error('Should be a valid name')),
                }]}
                style={{ display: 'inline-block', marginRight: '8px', width: '100%' }}
              >
                <Row gutter={4}>
                  <Col md={20}>
                    <Input
                      style={{
                        border: '1px solid #939ea8',
                        borderRadius: '2px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                      value={button.name || ''}
                      placeholder='Enter button name'
                      onChange={(e) => handleInputChange(button.id, e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <Handle type='source' position={Position.Right} id={button.id} className='edgebutton-handle' />
                    <Button type='link' onClick={() => deleteInput(button.id)} icon={<DeleteOutlined />} />
                  </Col>
                </Row>
              </Form.Item>
            ))}
          </Form>
          <Button type='dashed' onClick={addInput} disabled={buttonNames.length > 2} block>
            Add Button
          </Button>
        </Card>
      </div>
    </Tooltip>
  </div>
  
  );
};

export default InteractiveButtonNode;
