import React, { useState } from "react";
import { User } from "models";
import { Button, Col, Form, Input, Modal, notification, Row } from "antd";
import FormItemPhoneInput from "components/shared/FormItemPhoneInput";
import { phoneRequestOTPService, phoneVerifyOTPService, requestOTPWithPhoneService } from "services/auth/UserService";
import { claimFreeCreditService } from "services/dashboard/CreditService";
import { useDispatch } from "react-redux";
import { fetchUserWallet, fetchUser } from "redux/actions";
import { DollarOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const WalletCredit = (props: { user?: User, wallet?: number, testCredit?:number, localCurrency?: string }) => {
  const { user, wallet, testCredit, localCurrency } = props;
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showResend, setShowResend] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = (message: string) => {
    const notificationParam = {
      message,
      description: ''
    };
    notification.success(notificationParam);
  };

  const onError = (message: string, show: boolean) => {

    if (show) {
      const notificationParam = {
        message,
        description: ''
      };
      notification.error(notificationParam);
    }
  };

  const onClose = () => {
    setShowOTP(false);
    form.resetFields();
    setShowForm(false);
  };


  const getPhoneNumber = (phoneField: any) => {
    if (phoneField?.phone) {
      // eslint-disable-next-line no-nested-ternary
      const phone = phoneField.phone?.startsWith('+') ?
        phoneField.phone : phoneField.code? `+${phoneField.code}${phoneField.phone}`: `+${phoneField.phone}`;
      return phone.trim()
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll('[', '')
        .replaceAll(']', '');
    }
    return undefined;
  };


  const requestOTP = async () => {
    try {
      const response = await phoneRequestOTPService();
      if (response) {
        onSuccess('OTP Generated successfully');
        form.resetFields(['otpCode']);
      } else {
        onError('OTP Generate failed', true);
        form.resetFields(['otpCode']);
      }
    } catch (error: any) {
      onError(error.message, true);
      form.resetFields(['otpCode']);
    }
  };

  const claimFreeCredit = async () => {
    try {
      const response = await claimFreeCreditService();
      if (response) {
        onSuccess('Successfully activated free credits');
        dispatch(fetchUserWallet());
        dispatch(fetchUser());
        window.location.reload();
      }
    } catch (error: any) {
      onError(error.message, true);
    }
  };

  const onClaimButtonClick = () => {
    if (user?.profile?.mobile_verified) {
      claimFreeCredit();
    }
    else {
      setShowForm(true);
      if (user?.profile?.mobile) {
        requestOTP();
        setShowOTP(true);
        setShowResend(true);
      }
    }
  };

  const [isGenerated, setIsGenerated] = useState(false);


  const requestOTPWithPhone = async (phoneNumber: string) => {
    try {
      const response = await requestOTPWithPhoneService(phoneNumber);
      if (response) {
        if (response.detail === 'Last otp sent within 1 minute') {
          onError(response.detail, true);
          setShowOTP(false);
        } else {
          setShowOTP(true);
          onSuccess('OTP Generated successfully');
          form.resetFields(['otpCode']);
        }
      } else {
        onError('OTP Generate failed', true);
        form.resetFields(['otpCode']);
      }
    } catch (error: any) {
      onError(error.message, true);
      form.resetFields(['otpCode']);
    }
  };


  const onVerfiyOTP = async (otp: string, phoneNum?: string) => {
    try {
      const response = phoneNum ?
        await phoneVerifyOTPService({ mobile: phoneNum, otp }) :
        await phoneVerifyOTPService({ otp });
      if (response) {
        await claimFreeCredit();
        setShowForm(false);
      } else {
        form.resetFields(['otpCode']);
      }
    } catch (error: any) {
      onError(error.message, true);
      form.resetFields(['otpCode']);
    }

  };

  const handleRequestOTP = () => {
    setIsGenerated(true);
    if (user?.profile?.mobile) {
      requestOTP();
    } else {
      form.validateFields(['phone']);
      const phoneNumber = form.getFieldValue('phone');
      if (phoneNumber?.phone) {
        if (phoneNumber.phone !== user?.profile?.mobile) {
          const phone = getPhoneNumber(phoneNumber);
          requestOTPWithPhone(phone);
        } else {
          onError('Entred number already verified', true);
          setShowOTP(false);
          setIsGenerated(false);
        }
      }
    }
  };

  const onSubmit = async (values: {
    phone: any;
    otpCode: string
  }) => {
    const phone = getPhoneNumber(values.phone);
    onVerfiyOTP(values.otpCode, phone);
  };


  return (
    <>

      {(!user?.profile || !user?.profile?.free_credit_claim) && (!wallet || wallet < 0) && (!testCredit || testCredit < 0) &&
        <Button type="primary" className="mt-4" onClick={onClaimButtonClick}>{user?.account_type === 'SUB_ACCOUNT' ? 'Activate account' : 'Claim Free Credit'}</Button>}
      {!!((!wallet || wallet <=0) && (testCredit && testCredit > 0)) && 
     <Button
     type="primary"
     className="mt-4"
     onClick={() => navigate(`/billing/payment`)}
     style={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     justifyContent: "center",
     height: "40px",
     padding: "0 8px",
     borderRadius: "8px",
     textAlign: "center",
     width: "fit-content",
     minWidth: "120px",
       }}
     >

     <div style={{ fontSize: "12px", fontWeight: "bold", lineHeight: "14px",marginRight:'10px' }}>
       {testCredit} Credits
     </div>
     <div
     style={{
       fontSize: "10px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       lineHeight: "12px",
       marginTop: "2px",
     }}
   >
       Add Credit
       <DollarOutlined style={{ marginLeft: "3px", fontSize: "10px" }} />
       </div>
     </Button>
      }


      {(((wallet && wallet > 0) || (user?.profile?.free_credit_claim && (!testCredit || testCredit <= 0)))) && (
        <Button
          type="primary"
          className="mt-4"
          onClick={() => navigate(`/billing/payment`)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "40px",
            padding: "0 8px",
            borderRadius: "8px",
            textAlign: "center",
            width: "fit-content",
            minWidth: "120px",
          }}
        >
          <div style={{ fontSize: "12px", fontWeight: "bold", lineHeight: "14px" }}>
            {wallet}   {(localCurrency && localCurrency === "EURO" ? "EUR" : localCurrency) || "USD"}
          </div>
          <div
            style={{
              fontSize: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              lineHeight: "12px",
              marginTop: "2px",
            }}
          >
            Add Credit
            <DollarOutlined style={{ marginLeft: "3px", fontSize: "10px" }} />
          </div>
        </Button>
      )}
      <Modal
        visible={showForm}
        width={650}
        onCancel={onClose}
        onOk={onClose}
        footer={null}
      >

        <Form form={form} onFinish={onSubmit}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              {!user?.profile?.mobile && <Row gutter={16}>
                <Col xs={24} sm={24} md={18}>
                  <Form.Item
                    label="Mobile phone"
                    name="phone"
                    labelAlign="left"
                    rules={[{
                      required: true,
                      message: 'Please enter phone number'
                    }]}
                    labelCol={{ span: 7 }}
                  >
                    <FormItemPhoneInput form={form} name='phone' />
                  </Form.Item>
                </Col>
                <Button type="primary" onClick={handleRequestOTP} >{isGenerated ? 'Resend OTP' : 'Generate OTP'}</Button>
              </Row>}
              <Row gutter={16} hidden={!showOTP}>
                <Col xs={24} sm={24} md={18}>
                  <Form.Item
                    label="OTP Code"
                    name="otpCode"
                    labelAlign="left"
                    rules={[{
                      required: true,
                      message: 'Please enter  valid otp!'
                    }]}
                    labelCol={{ span: 7 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Button type="primary" htmlType="submit">Verify OTP</Button>
              </Row>
              <Row gutter={16} hidden={!showResend} justify='end' className="mr-2">
                <Button type="primary" className="mr-4" onClick={handleRequestOTP} >Resend OTP</Button>
              </Row>
            </Col>
          </Row>
        </Form>

      </Modal>
    </>
  );
};

WalletCredit.defaultProps = {
  user: undefined,
  wallet: undefined,
  testCredit: undefined,
  localCurrency: undefined
};

export default WalletCredit;
