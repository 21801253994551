import React from "react";
import { notification } from "antd";

interface NotificationMessage {
  message: {
    id: string;
    name: string;
    text: string;
    phone: string;
    time: string;
    originator_number:string
  };
  selectedOriginator:string
  onClose: () => void;
}

const NotificationPopup: React.FC<NotificationMessage> = ({ message, onClose,selectedOriginator }) => {
    const key = message.id;
    
    notification.open({
      placement: "topRight",
      duration:2,
      onClick:onClose,
      key,
      message: (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <strong>{message.name}</strong>
        </div>
      ),
      description: (
        <p style={{ margin: 0 }}>{message.text} <br/> 
        <span style={{ fontWeight: 'bold', color: '#0865fe' }}> {selectedOriginator}</span>
        </p> 
      ),
      icon: (
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          style={{ width: 30, height: 30 }}
        />
      ),
    });

  return null;
};

export default NotificationPopup;


