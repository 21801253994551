import React, { useEffect, useState, useCallback } from "react";
import { Handle, Position, useReactFlow, useStoreApi } from "reactflow";
import { Button, Card, Form, Input, Tooltip, Upload, message } from "antd";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/es/upload/interface";
import { uploadChatbotMediaFile } from "services/dashboard/WhatsappService";
import useFormErrors from 'hooks/useFormErrors';

interface DocumentNodeProps {
  id: string;
  data: {
    msg_type: "ATTACHMENT";
    payload_info?: {
      url?: string;
      fileName?: string;
      caption?: string;
      content_type?: "document";
    };
  };
}

const allowedDocumentTypes = [
  "application/pdf", "text/plain", 
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
  "application/vnd.ms-excel", 
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
  "application/msword", 
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
  "application/vnd.ms-powerpoint"
];

const DocumentMessageNode = ({ id, data }: DocumentNodeProps) => {
  const { setNodes, deleteElements } = useReactFlow();
  const store = useStoreApi();
  const [form] = Form.useForm();
  const { updateFormErrors, deleteFormError } = useFormErrors();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);

  useEffect(() => {
    if (data.payload_info) {
      form.setFieldsValue({ 
        caption: data.payload_info.caption,
        fileName: data.payload_info.fileName || "", 
      });

      if (data.payload_info.url && data.payload_info.fileName) {
        setFileList([
          {
            uid: "-1",
            name: data.payload_info.fileName,
            status: "done",
            url: data.payload_info.url,
          },
        ]);
      }
    }
  }, [data, form]);

  const updateNodeData = useCallback(
    (updatedPayload: any) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) =>
          node.id === id
            ? {
                ...node,
                data: {
                  ...node.data,
                  msg_type: "ATTACHMENT",
                  payload_info: {
                    ...updatedPayload,
                    content_type: "document",
                  },
                },
              }
            : node
        )
      );
    },
    [id, setNodes, store]
  );

  const handleMouseLeave = () => {
    form.validateFields().then(() => {
      const caption = form.getFieldValue("caption");
      const fileName = form.getFieldValue("fileName");

      if (!fileList || fileList.length === 0) {
        updateFormErrors(id, true);
      } else {
        updateFormErrors(id, false);
        updateNodeData({
          ...data.payload_info,
          caption,
          fileName,
        });
      }
    }).catch(() => {
      updateFormErrors(id, true);
    });
  };

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(id);
    deleteElements({ nodes: [{ id }] });
  };

  const fileUploadCustomRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;
    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; 

    if (!allowedDocumentTypes.includes(fileType)) {
      message.error("Only PDF, DOCX, DOC, TXT, XLS, XLSX, PPT, PPTX files are allowed!");
      onError("Invalid file type");
      return;
    }

    if (fileSize > 100) {
      message.error("Document must be smaller than 100MB!");
      onError("File size too large");
      return;
    }

    setUploadingFile(true);

    try {
      const response = await uploadChatbotMediaFile(file) ;

      if (response.id !== "None") {
        const url = response.id;
        onSuccess(response, file);

        updateNodeData({
          ...data.payload_info,
          url,
          fileName: file.name,
        });

        setUploadingFile(false);
      } else {
        message.error(`${file.name} file type is not supported.`);
        setUploadingFile(false);
        onError("Unsupported file type");
      }
    } catch (error) {
      message.error("File upload failed. Please try again.");
      setUploadingFile(false);
      onError("File upload failed");
    }
  };

  const onChangeFile = (info: any) => {
    setFileList(info.fileList);
    const { status } = info.file;

    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setFileList([]);
      updateNodeData({
        ...data.payload_info,
        url: undefined,
        fileName: undefined,
      });
    } else if (status === "removed") {
      setFileList([]);
      updateNodeData({
        ...data.payload_info,
        url: undefined,
        fileName: undefined,
      });
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Handle type="target" position={Position.Left} className="edgebutton-handle" />
      <Tooltip
        color="white"
        title={
          <Button
            title="Delete"
            onClick={onDeleteNode}
            icon={<DeleteOutlined />}
            style={{ height: "35px", width: "35px" }}
          />
        }
        placement="rightTop"
      >
        <div style={{ position: "relative", display: "inline-block" }}>
          <Card title="Document Message" style={{ width: "400px", border: "1px solid #173409FF" }} onMouseLeave={handleMouseLeave}>
            <Form form={form} name="document_form">
              <Form.Item
                label={<><span style={{ color: "red", fontSize: "16px",marginRight:5 }}>*</span> Upload Document</>}
                name="document"
              >
                <Upload
                  name="file"
                  accept={allowedDocumentTypes.join(", ")}
                  maxCount={1}
                  customRequest={fileUploadCustomRequest}
                  onChange={onChangeFile}
                  fileList={fileList}
                  showUploadList={{
                    showRemoveIcon: true,
                    removeIcon: <DeleteOutlined />,
                  }}
                >
                  <Button type="default" className="bg-gray-lightest mx-2 px-5 w-100" loading={uploadingFile}>
                    <CloudUploadOutlined /> Upload
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item name="fileName" label="File Name">
                <Input placeholder="Enter file name" />
              </Form.Item>

              <Form.Item name="caption" label="Caption">
                <Input.TextArea placeholder="Add a caption for your document" autoSize={{ minRows: 2 }} />
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Tooltip>
      <Handle type="source" position={Position.Right} className="edgebutton-handle" />
    </div>
  );
};

export default DocumentMessageNode;
