import { useEffect, useState, useRef, useCallback } from 'react';

const useAblyChannel = (
  ablyClient: any,
  channelName: string,
  enabled: boolean = true,
  trackPresence: boolean = false,
  isVisible: boolean = true
) => {
  const [message, setMessage] = useState<any>();
  const channelRef = useRef<any>(null);
  const messageHandlerRef = useRef<any>(null);
  const isActiveRef = useRef(true); 

  const handleMessage = useCallback((msg: any) => {
    setMessage(msg);
  }, []);

  const cleanup = useCallback(async () => {
    if (!channelRef.current) return;

    try {
      if (trackPresence && ablyClient?.auth?.clientId) {
        await new Promise((resolve) => {
          channelRef.current.presence.leave((err: any) => {
            if (err) console.error('Presence leave failed:', err);
            else console.log('Presence leave successful');
            resolve(null);
          });
        });
      }


      channelRef.current.unsubscribe(messageHandlerRef.current);
      await new Promise((resolve) => {
        channelRef.current.detach((err: any) => {
          if (err) console.error('Channel detach failed:', err);
          else console.log('Channel detached successfully:', channelName);
          resolve(null);
        });
      });

      await new Promise((resolve) => {
        ablyClient.channels.release(channelName, (err: any) => {
          if (err) console.error('Channel release failed:', err);
          else console.log('Channel released successfully:', channelName);
          resolve(null);
        });
      });

      channelRef.current = null;
    } catch (error) {
      console.error('Error cleaning up channel:', error);
    }
  }, [ablyClient, channelName, trackPresence]);


  const setupChannel = useCallback(async () => {
    if (!enabled || !ablyClient || !isActiveRef.current || !isVisible) {
      await cleanup();
      return;
    }

    try {
      channelRef.current = ablyClient.channels.get(channelName);

      if (channelRef.current) {
        // Subscribe to channel
        channelRef.current.subscribe(messageHandlerRef.current);

        if (trackPresence && ablyClient.auth.clientId) {
          await new Promise((resolve, reject) => {
            channelRef.current.presence.enter(null, (err: any) => {
              if (err) {
                console.error('Presence enter failed:', err);
                reject(err);
              } else {
                console.log('Presence enter successful');
                resolve(null);
              }
            });
          });
        }
      }
    } catch (error) {
      console.error('Error setting up channel:', error);
    }
  }, [ablyClient, channelName, enabled, trackPresence, cleanup, isVisible]);


  useEffect(() => {
    isActiveRef.current = true; 
    if (!isVisible) {
      cleanup();
    } else {
      setupChannel();
    }

    return () => {
      isActiveRef.current = false;
      cleanup();
    };
  }, [ablyClient, channelName, enabled, trackPresence, isVisible, cleanup, setupChannel]);

 
  messageHandlerRef.current = handleMessage;

  return message;
};

export default useAblyChannel;
