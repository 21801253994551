import React, { useEffect, useCallback } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';
import { Button, Card, Form, Input, InputNumber, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useFormErrors from 'hooks/useFormErrors';

interface LocationNodeProps {
  id: string;
  data: {
    msg_type: 'LOCATION';
    payload_info?: {
      latitude?: string;
      longitude?: string;
      name?: string;
      address?: string;
    };
  };
}

const LocationMessageNode = ({ id, data }: LocationNodeProps) => {
  const { setNodes, deleteElements } = useReactFlow();
  const store = useStoreApi();
  const [form] = Form.useForm();
  const { updateFormErrors, deleteFormError } = useFormErrors();
  
  useEffect(() => {
    if (data.payload_info) {
      form.setFieldsValue({
        latitude: data.payload_info.latitude,
        longitude: data.payload_info.longitude,
        name: data.payload_info.name,
        address: data.payload_info.address,
      });
    }
  }, [data, form]);

  const updateNodeData = useCallback(
    (updatedPayload: any) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) =>
          node.id === id
            ? {
                ...node,
                data: { 
                  ...node.data, 
                  msg_type: 'LOCATION',
                  payload_info: {
                    ...updatedPayload
                  }
                },
              }
            : node
        )
      );
    },
    [id, setNodes, store]
  );

  const handleMouseLeave = () => {
    form
      .validateFields()
      .then(() => {
        const { latitude, longitude, name, address } = form.getFieldsValue();

        if (!latitude || !longitude) {
          updateFormErrors(id, true);
        } else {
          updateFormErrors(id, false);
          updateNodeData({
            latitude,
            longitude,
            name,
            address,
          });
        }
      })
      .catch(() => {
        updateFormErrors(id, true);
      });
  };

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(id);
    deleteElements({ nodes: [{ id }] });
  };


  return (
    <div style={{ position: 'relative' }}>
      <Handle type="target" position={Position.Left} className="edgebutton-handle" />
      <Tooltip
        color="white"
        title={
          <Button
            title="Delete"
            onClick={onDeleteNode}
            icon={<DeleteOutlined />}
            style={{ height: '35px', width: '35px' }}
          />
        }
        placement="rightTop"
      >
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Card title="Location Message" style={{ width: '400px', border: "1px solid #173409FF" }} onMouseLeave={handleMouseLeave}>
            <Form form={form} name="location_form">
              <Form.Item
                label="Latitude"
                name="latitude"
                rules={[{ required: true, message: "Please enter latitude!" }]}
              >
                 <InputNumber
                      style={{ width: '100%' }}
                      placeholder="Enter Latitude"
                      onChange={(value) => form.setFieldsValue({ latitude: value })}
                    />
              </Form.Item>
              <Form.Item
                    label="Longitude"
                    name="longitude"
                    rules={[{ required: true, message: "Please enter longitude!" }]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="Enter Longitude"
                      onChange={(value) => form.setFieldsValue({ longitude: value })}
                    />
                  </Form.Item>
              <Form.Item
                label="Name"
                name="name"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Address"
                name="address"
              >
                <Input />
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Tooltip>
      <Handle type="source" position={Position.Right} className="edgebutton-handle" />
    </div>
  );
};

export default LocationMessageNode;
