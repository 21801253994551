import React, { Suspense } from 'react';
import { APP_NAME } from 'configs/AppConfig';
import { Outlet } from 'react-router-dom';
import Flex from 'components/shared/Flex';
import { useSelector } from 'react-redux';
import { RootState } from 'App';
import Loading from 'components/shared/Loading';

const ErrorPageLayout = () => {
	const theme = useSelector((state: RootState) => state.theme.currentTheme);
	return (
		<Suspense fallback={<Loading cover='page' />}>
			<div className="auth-container">
				<div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
					<div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
						<div>
							<img className="img-fluid" src={`/img/${theme === 'light' ? 'direct7-logo 3.svg' : 'logo-white.png'}`} alt="" />
						</div>
						<div className="container">
							<Outlet />
						</div>

						<Flex mobileFlex={false} justifyContent="between">
							<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span></span>
							<div>
								<a className="text-white" href="https://d7networks.com/terms/" target='blank'>Terms of Service</a>
								<span className="mx-2 text-white"> | </span>
								<a className="text-white" href="https://d7networks.com/privacy/" target='blank'>Privacy Policy</a>
							</div>
						</Flex>
					</div>
				</div>
			</div>
		</Suspense>

	);
};

export default ErrorPageLayout;


