import React, { useEffect, useState } from 'react';
import * as Ably from 'ably';
import { ABLY_API_KEY } from 'configs/AppConfig';

const useAbly = (clientId?: string) => {
  const [ablyClient, setAblyClient] = useState<Ably.Realtime | undefined>(undefined);

  useEffect(() => {
    let client: Ably.Realtime | any;
    if (ABLY_API_KEY) {

      if (clientId) {
        client = new Ably.Realtime({ key: ABLY_API_KEY, clientId });
      } else {
        client = new Ably.Realtime({ key: ABLY_API_KEY });
      }

      client.connection.on('connected', () => {
        console.log(`Ably client connected successfully${clientId ? ` with clientId: ${clientId}` : ''}`);
      });

      client.connection.on('disconnected', () => {
        console.log('Ably client disconnected');
      });

      setAblyClient(client);
    }
      return () => {
        if (!client) return;
        // Unsubscribe from all channels and leave all presence events
        const promises = Object.keys(client.channels.all).map(channelName => {
          const channel = client.channels.all[channelName];
          return new Promise<void>((resolve) => {
            // channel.presence.leave((err:any) => {
            //   if (err) {
            //     console.error('Presence leave failed:', err);
            //   }
            //   channel.unsubscribe();
            //   resolve();
            // });
            channel.unsubscribe();
          });
        });

        Promise.all(promises).then(() => {
          client.close();
          console.log('Ably client cleaned up with clientId:', clientId);
        });
        console.log('Ably client closed');
      };
  }, [clientId]);

  return ablyClient;
};

export default useAbly;

export const AblyInstanceContext = React.createContext<Ably.Realtime | undefined>(undefined);
