import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal
} from "antd";
import Picker from 'emoji-picker-react';
import {InfoCircleOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {WhatsappTemplateDetails} from "../../../../../../models";


export interface StepTwoBodyComponentProps {
  form?: any;
  templateType?: any;
  onBodyTextChanged?: any;
  onExampleBodyTextChanged?: any;
  editItem?: WhatsappTemplateDetails;
  isTemplateTypeChange?: boolean;
  isCategoryTypeChange?: boolean;
  inputDirection?: any;
  currentStep: number;
}

const StepTwoBodyComponent = (props: StepTwoBodyComponentProps) => {

  const {currentStep, templateType, onBodyTextChanged, onExampleBodyTextChanged, editItem, form, isTemplateTypeChange, isCategoryTypeChange, inputDirection} = props;
  const [bodyText, setBodyText] = useState('');
  const [exampleBodyValues, setExampleBodyValues] = useState<any>({});
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  // const [chosenEmoji, setChosenEmoji] = useState(null);



  const handleAddVariableToBody = () => {
    const bodyVariables = bodyText.match(/{{(\d+)}}/g);
    const lastVariableCount = bodyVariables
      ? Math.max(...bodyVariables.map((match: any) => parseInt(match.match(/{{(\d+)}}/)[1], 10)))
      : 0;
    const newBodyVariableIndex = (lastVariableCount + 1).toString();
    const textBeforeCursor = bodyText.slice(0, selectionStart);
    const textAfterCursor = bodyText.slice(selectionEnd);
    const updatedBodyText = `${textBeforeCursor}{{${newBodyVariableIndex}}}${textAfterCursor}`;
    setBodyText(updatedBodyText);
    const updatedExampleBodyValues = {
     ...exampleBodyValues,
    [newBodyVariableIndex]: '',
    };
    setExampleBodyValues(updatedExampleBodyValues);

    if (onBodyTextChanged) {
      onBodyTextChanged(updatedBodyText);
    }

    if (onExampleBodyTextChanged) {
      onExampleBodyTextChanged(updatedExampleBodyValues);
    }

    form.setFieldsValue({body_text: updatedBodyText});
  };

  // Notify the parent component about the updated user-typed value
  const handleBodyTextChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = target;
    setBodyText(value);
    if (onBodyTextChanged) {
      onBodyTextChanged(value);
    }

    const bodyVariables = value.match(/{{(\d+)}}/g);
    const updatedExampleBodyValues: any = {};
    if (bodyVariables) {
    bodyVariables.forEach((item: any, index) => {
      updatedExampleBodyValues[index + 1] = exampleBodyValues[index + 1];
    });
  }
    setExampleBodyValues(updatedExampleBodyValues);
    if (onExampleBodyTextChanged) {
      onExampleBodyTextChanged(updatedExampleBodyValues);
    }

  };

  const handleTextAreaSelection = (e: any) => {
    setSelectionStart(e.target.selectionStart);
    setSelectionEnd(e.target.selectionEnd);
  };

  const applyFormatting = (format: any) => {
    if (selectionStart !== selectionEnd) {
      let startSymbol = '';
      let endSymbol = '';

      switch (format) {
        case 'bold':
          startSymbol = '*';
          endSymbol = '*';
          break;
        case 'italic':
          startSymbol = '_';
          endSymbol = '_';
          break;
        case 'strike':
          startSymbol = '~';
          endSymbol = '~';
          break;
        case 'code':
          startSymbol = '```';
          endSymbol = '```';
          break;
        default:
          break;
      }

      setBodyText((prevText) => {
        const textBeforeSelection = prevText.slice(0, selectionStart);
        const selectedText = prevText.slice(selectionStart, selectionEnd);
        const textAfterSelection = prevText.slice(selectionEnd);
        const newBody = `${textBeforeSelection}${startSymbol}${selectedText}${endSymbol}${textAfterSelection}`;
        if (onBodyTextChanged) {
          onBodyTextChanged(newBody);
        }
        form.setFieldsValue({body_text: newBody});
        return newBody;
      });
    }
  };

  const handleEmojiClick = (emojiObject: any) => {
    const { emoji } = emojiObject;
    const newText = `${bodyText.slice(0, selectionStart)}${emoji}${bodyText.slice(selectionEnd)}`;
    setBodyText(newText);
    if (onBodyTextChanged) {
      onBodyTextChanged(newText);
    }
    form.setFieldsValue({body_text: newText});
    setShowEmojiPicker(false);
  };


  useEffect(() => {
    if (editItem) {
      setBodyText(editItem.body_text || '');
      setExampleBodyValues(editItem.example_body_text);
    }
    else if (form.getFieldValue('body_text')){
      setBodyText(form.getFieldValue('body_text'));
    }

    if (form.getFieldValue('example_body_text')) {
      setExampleBodyValues(form.getFieldValue('example_body_text'));
    }

    if (isTemplateTypeChange){
      setBodyText("");
      setExampleBodyValues("");
      form.setFieldsValue({ body_text: undefined });
      form.setFieldsValue({ example_body_text: undefined });
    }
    if (isCategoryTypeChange){
      setBodyText("");
      setExampleBodyValues("");
      form.setFieldsValue({ body_text: undefined });
      form.setFieldsValue({ example_body_text: undefined });
    }
  }, [editItem, currentStep]);

  return (
    <>
      <Col xs={24} sm={24} md={24}>
        <h4>
          <span style={{color: 'orange', marginLeft: 4, fontSize: "smaller"}}>*</span> Body
        </h4>
      </Col>

      <Col xs={24} sm={24} md={24}>
        <Form.Item
          className="mb-0"
          name="body_text"
          labelAlign='left'
          extra={`Maximum length is ${templateType === 'LIMITED_TIME_OFFER' ? 600 : 1024} chars`}
          labelCol={{span: 3}}
          rules={[
            {
              required: true,
              message: 'Please enter Body text',
            },
            {
              max: templateType === 'LIMITED_TIME_OFFER' ? 600 : 1024,
              message: `Body text for ${templateType} cannot exceed ${templateType === 'lto' ? 600 : 1024} characters`,
            },
            // {
            //   validator: (rule, value) => {
            //     if (value && (value.toLowerCase().includes('code') || value.toLowerCase().includes('otp'))) {
            //       return Promise.reject(new Error('Body text should not contain authentication related keywords like: code, otp, etc'));
            //     }
            //     return Promise.resolve();
            //   },
            // },
            {
              validator: (_, value) => {
                const variableRegex = /{{\d+}}/g;
                const variableMatches = value ? value.match(variableRegex) : [];

                if (!variableMatches || variableMatches.length === 0) {
                  // If there are no variables, it's valid.
                  return Promise.resolve();
                }

                const variableIndexes = variableMatches.map((match: any) =>
                  parseInt(match.match(/\d+/)[0], 10)
                );

                // Check if the variables start from {{1}} and are sequential.
                let valid = true;
                for (let i = 1; i <= variableIndexes.length; i += 1) {
                  if (variableIndexes[i - 1] !== i) {
                    valid = false;
                    break; // Exit the loop early if an invalid index is found.
                  }
                }

                if (!valid) {
                  return Promise.reject(new Error('Variables must start from {{1}} and be sequential.'));
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <TextArea
              value={bodyText}
              onChange={handleBodyTextChange}
              onSelect={handleTextAreaSelection}
              /* eslint-disable react/no-array-index-key */
              style={{height: '150px'}}
              dir={inputDirection}
            />
            <Button
              onClick={handleAddVariableToBody}
              style={{
                border: 'none',
                background: 'transparent',
                marginLeft: '8px',
              }}
            >
              Add Variable +
            </Button>
          </div>
        </Form.Item>
        <div style={{textAlign: 'right', marginRight: '125px'}}>
          <Button className="pl-3" type='text' onClick={() => applyFormatting('bold')}>
            <b>B</b>
          </Button>
          <Button className="pl-3" type='text' onClick={() => applyFormatting('italic')}>
            <b>I</b>
          </Button>
          <Button className="pl-3" type='text' onClick={() => applyFormatting('strike')}>
            <b>s̶</b>
          </Button>
          <Button className="pl-3" type='text' onClick={() => applyFormatting('code')}>
            <b>{`</>`}</b>
          </Button>
          <Button
            className="pl-3"
            type='text'
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          >
            😃
          </Button>
          {showEmojiPicker && (
            <div style={{ position: 'absolute', bottom: '30px', right: '20px' }}>
              <Picker onEmojiClick={handleEmojiClick} />
            </div>
          )}
        </div>
      </Col>

      <Col xs={24} sm={24} md={24}>
        {/* Example Data Input for Body Text Variables */}
        {bodyText && bodyText.includes('{{') && (
          <Card
            title={
              <div style={{display: 'flex', alignItems: 'center'}}>
                Samples for Body Content
                <InfoCircleOutlined
                  style={{fontSize: '18px', marginLeft: '8px'}}
                  onClick={() => {
                    // Show info content (You can use a modal or tooltip)
                    Modal.info({
                      title: "Variable Example Information",
                      content: (
                        <div>
                          To help us review your message template,
                          please add an example for each variable in
                          your body text. Do not use real customer
                          information.
                        </div>
                      ),
                    });
                  }}
                />
              </div>
            }
            style={{
              backgroundColor: 'lightgray',
              marginBottom: '16px',
              textAlign: 'center',
              opacity: "70%"
            }}
          >
            <Form.Item
              name="example_body_text"
              labelAlign="left"
              labelCol={{span: 3}}
              rules={[
                {
                  required: !Object.values(exampleBodyValues).some(
                    (value) => value !== undefined && value !== ''
                  ),
                  message: 'Please provide example values for all variables',
                },
              ]}
            >
              {bodyText && bodyText.split('{{').map((part, index) => {
                if (part && part.includes('}}')) {
                  const variableIndex: any = part.split('}}')[0];
                  return (
                    <Input
                      key={index}
                      name={`example_body_text[${variableIndex}]`}
                      dir={inputDirection}
                      placeholder={`Example value for {{${variableIndex}}}`}
                      style={{marginBottom: '8px'}}
                      value={exampleBodyValues[variableIndex]}
                      onChange={(e) => {
                        const newExampleValues = {...exampleBodyValues};
                        newExampleValues[variableIndex] = e.target.value;
                        setExampleBodyValues(newExampleValues);
                        // Call the onExampleBodyTextChanged function with the updated values
                        if (onExampleBodyTextChanged) {
                          onExampleBodyTextChanged(newExampleValues);
                        }
                      }}
                    />
                  );
                }
                return null;
              })}
            </Form.Item>
          </Card>
        )}
      </Col>
    </>
  );
};

StepTwoBodyComponent.defaultProps = {
  templateType: undefined,
  form: undefined,
  onBodyTextChanged: undefined,
  onExampleBodyTextChanged: undefined,
  editItem: undefined,
  isTemplateTypeChange: undefined,
  isCategoryTypeChange: undefined,
  inputDirection: undefined,
};


export default StepTwoBodyComponent;
