import React, { useEffect, useState, useCallback } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';
import { Button, Card, Form, Upload, message, Tooltip } from "antd";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { UploadFile } from 'antd/es/upload/interface';
import { uploadChatbotMediaFile } from 'services/dashboard/WhatsappService';
import { showErrorNotification } from 'utils/helper';
import useFormErrors from 'hooks/useFormErrors';

interface StickerNodeProps {
  id: string;
  data: {
    msg_type: 'ATTACHMENT';
    payload_info?: {
      url?: string;
      content_type?: 'sticker';
    };
  };
}

const StickerMessageNode = ({ id, data }: StickerNodeProps) => {
  const { setNodes, deleteElements } = useReactFlow();
  const store = useStoreApi();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const {updateFormErrors, deleteFormError } = useFormErrors();
  const [uploadError, setUploadError] = useState<string | null>(null);

  useEffect(() => {
    if (data.payload_info && data.payload_info.url) {
      setFileList([
        {
          uid: '-1',
          name: data.payload_info.url.split('/').pop() || 'Sticker',
          status: 'done',
          url: data.payload_info.url,
        },
      ]);
    }
  }, [data]);

  const updateNodeData = useCallback(
    (updatedPayload: any) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) =>
          node.id === id
            ? {
                ...node,
                data: {
                  ...node.data,
                  msg_type: 'ATTACHMENT',
                  payload_info: {
                    ...updatedPayload,
                    content_type: 'sticker',
                  },
                },
              }
            : node
        )
      );
    },
    [id, setNodes, store]
  );

  const onDeleteNode = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    deleteFormError(id);
    deleteElements({ nodes: [{ id }] });
  };

  const fileUploadCustomRequest = async (options: any) => {
    const { file, onSuccess } = options;
    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; 

    if (fileType !== 'image/webp') {
      showErrorNotification('Invalid file type. Only .webp is allowed.');
      setFileList([]);
      return;
    }

    if (fileSize > 0.5) { 
      showErrorNotification('File size exceeds the limit.');
      setFileList([]);
      return;
    }

    setUploadingFile(true);

    try {
      const response = await uploadChatbotMediaFile(file);

      if (response.id !== 'None') {
        const url = response.id;
        onSuccess(response, file);

        updateNodeData({
          url,
        });
        setUploadError(null);
        setUploadingFile(false);
      } else {
        message.error(`${file.name} file type is not supported.`);
        setFileList([]);
        setUploadingFile(false);
      }
    } catch (error:any) {
      const errorMsg =error.message;
      setUploadError(errorMsg);
      setFileList([]);
      setUploadingFile(false);
    }
  };

  const onChangeFile = (info: any) => {
    const { status,originFileObj } = info.file;

    if (originFileObj) {
      const img = new Image();
      img.src = URL.createObjectURL(originFileObj);
  
      img.onload = () => {
        if (img.width !== 512 || img.height !== 512) {
          setUploadError("Sticker must be exactly 512x512 pixels.");
          setFileList([]);
        }  else {
          setUploadError(null);
          setFileList(info.fileList);
        }
      };
    }

    if (status === 'done') {
      setUploadError(null);
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      setFileList([]);
      updateNodeData({
        url: undefined,
      });
    } else if (status === 'removed') {
      setFileList([]);
      updateNodeData({
        url: undefined,
      });
    }
  };

  const handleMouseLeave = () => {
    if (fileList.length === 0) {
      updateFormErrors(id, true);
    } else {
      updateFormErrors(id, false);
      updateNodeData({
        url: data.payload_info?.url,
      });
    }
  };
  return (
    <div style={{ position: 'relative' }}>
      <Handle type="target" position={Position.Left} className="edgebutton-handle" />
      <Tooltip
        color="white"
        title={
          <Button
            title="Delete"
            onClick={onDeleteNode}
            icon={<DeleteOutlined />}
            style={{ height: '35px', width: '35px' }}
          />
        }
        placement="rightTop"
      >
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Card title="Sticker Message" style={{ width: '400px', border: "1px solid #173409FF" }}  onMouseLeave={handleMouseLeave}>
            <Form name="sticker_form">
              <Form.Item
                label={<><span style={{ color: 'red', fontSize: '16px', marginRight: 5 }}>*</span> Upload Sticker</>}
                name="sticker"
                validateStatus={uploadError ? "error" : ""}
                help={uploadError}
              >
                <Upload
                  name="file"
                  accept=".webp"
                  maxCount={1}
                  customRequest={fileUploadCustomRequest}
                  onChange={onChangeFile}
                  fileList={fileList}
                  showUploadList={{
                    showRemoveIcon: true,
                    removeIcon: <DeleteOutlined />,
                  }}
                >
                  <Button type="default" className="bg-gray-lightest mx-2 px-5 w-100" loading={uploadingFile}>
                    <CloudUploadOutlined /> Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Tooltip>
      <Handle type="source" position={Position.Right} className="edgebutton-handle" />
    </div>
  );
};

export default StickerMessageNode;
