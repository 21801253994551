import {
  ChangePasswordRequest,
  EmailRequestOTPRequest,
  EmailVerifyOTPRequest,
  ForgotPasswordTokenRequest,
  PhoneVerifyOTPRequest,
  ProfileUpdateRequest,
  SubAccountAddRequest,
  SubAccountEditRequest,
  SubAccountPriceCreateRequest,
  SubAccountPriceEditRequest,
  SubUserCreateRequest,
  SubUserUpdateRequest,
  UserAddressCreateRequest,
  UserAddressUpdateRequest,
  UserSetPasswordRequest,
  VerifyForgotPasswordRequest,
  VerifyOtpRequest
} from 'models/requests';

import {
  getUserAddressList,
  createUserAddress,
  updateUserAddress,
  deleteUserAddress,
  getUser,
  requestOtp,
  verifyOtp,
  requestForgotPasswordToken,
  verifyForgotPassword,
  changePassword,
  getUserProfile,
  updateUserProfile,
  emailRequestOTP,
  emailVerifyOTP,
  phoneRequestOTP,
  phoneVerifyOTP,
  getAllSubAccounts,
  deleteSubAccount,
  getSubAccountPrice,
  addSubAccount,
  editSubAccount,
  createSubAccountPrice,
  editSubAccountPrice,
  deleteSubAccountPrice,
  getSubUsers,
  createSubUser,
  updateSubUser,
  deleteSubUser,
  getSubAccount,
  getAccessList,
  userSetPassword,
  getBuyingCost,
  requestOTPWithPhone,
  editAllocateCredit,
  getWhitelistedIp,
  addIpWhiteliste
} from 'api/auth/UserApi';

const getUserAddressListService = async () => getUserAddressList();
const createUserAddressService = async (data: UserAddressCreateRequest) => createUserAddress(data);
const updateUserAddressService = async (data: UserAddressUpdateRequest, addressId: string) => updateUserAddress(data, addressId);
const deleteUserAddressService = async (addressId: string) => deleteUserAddress(addressId);
const getUserService = async () => getUser();
const requestOtpService = async () => requestOtp();
const verifyOtpService = async (data: VerifyOtpRequest) => verifyOtp(data);
const requestForgotPasswordTokenService = (data: ForgotPasswordTokenRequest) => requestForgotPasswordToken(data);
const verifyForgotPasswordService = (data: VerifyForgotPasswordRequest) => verifyForgotPassword(data);
const changePasswordService = (data: ChangePasswordRequest) => changePassword(data);
const getUserProfileService = () => getUserProfile();
const updateUserProfileService = (data: ProfileUpdateRequest) => updateUserProfile(data);
const emailRequestOTPService = (data: EmailRequestOTPRequest) => emailRequestOTP(data);
const emailVerifyOTPService = (data: EmailVerifyOTPRequest) => emailVerifyOTP(data);
const phoneRequestOTPService = () => phoneRequestOTP();
const phoneVerifyOTPService = (data: PhoneVerifyOTPRequest) => phoneVerifyOTP(data);

const getAllSubAccountsService = () => getAllSubAccounts();
const getSubAccountService = (id: string) => getSubAccount(id);
const addSubAccountService = (data: SubAccountAddRequest) => addSubAccount(data);
const getSubAccountPriceService = (id: string) => getSubAccountPrice(id);
const deleteSubAccountService = (id: string) => deleteSubAccount(id);
const editSubAccountService = (id: string, data: SubAccountEditRequest) => editSubAccount(id, data);

const createSubAccountPriceService = (id: string, data: SubAccountPriceCreateRequest) => createSubAccountPrice(id, data);
const editSubAccountPriceService = (id: number, data: SubAccountPriceEditRequest) => editSubAccountPrice(id, data);
const deleteSubAccountPriceService = (id: number) => deleteSubAccountPrice(id);

const getSubUsersService = () => getSubUsers();
const createSubUserService = (data: SubUserCreateRequest) => createSubUser(data);
const updateSubUserService = (id: string, data: SubUserUpdateRequest) => updateSubUser(id, data);
const deleteSubUserService = (id: string) => deleteSubUser(id);
const getAccessListService = async () => getAccessList();

const userSetPasswordService = async (data: UserSetPasswordRequest) => userSetPassword(data);
const getBuyingCostService = (country: string) => getBuyingCost(country);

const requestOTPWithPhoneService = (phone: string) => requestOTPWithPhone({ mobile: phone });

const editAllocateCreditService = async (subAccountId: string, amount: number) => editAllocateCredit(subAccountId, { credit_amount: amount });
const getWhitelistedIpService = async () => getWhitelistedIp() ;
const addIpWhitelisteService = async (ipList: string[]) => addIpWhiteliste(ipList) ;
export {
  getUserAddressListService,
  createUserAddressService,
  updateUserAddressService,
  deleteUserAddressService,
  getUserService,
  requestOtpService,
  verifyOtpService,
  requestForgotPasswordTokenService,
  verifyForgotPasswordService,
  changePasswordService,
  getUserProfileService,
  updateUserProfileService,
  emailRequestOTPService,
  emailVerifyOTPService,
  phoneRequestOTPService,
  phoneVerifyOTPService,
  getAllSubAccountsService,
  getSubAccountService,
  deleteSubAccountService,
  getSubAccountPriceService,
  addSubAccountService,
  editSubAccountService,
  createSubAccountPriceService,
  editSubAccountPriceService,
  deleteSubAccountPriceService,
  getSubUsersService,
  createSubUserService,
  updateSubUserService,
  deleteSubUserService,
  getAccessListService,
  userSetPasswordService,
  getBuyingCostService,
  requestOTPWithPhoneService,
  editAllocateCreditService,
  getWhitelistedIpService,
  addIpWhitelisteService
};
