import {
  NumberLookupRequest,
  SMSMessageSendRequest,
  VerifyApiResendOTPRequest,
  VerifyApiSendOTPRequest,
  VerifyApiVerifyOTPRequest, ViberMessageSendRequest, WhatsappMessageSendRequest
} from 'models/requests';

import {
  SMSMessageSendResponse,
  ViberMessageSendResponse,
  VerifyApiResendOTPResponse,
  VerifyApiSendOTPResponse,
  VerifyApiVerifyOTPResponse, NumberLookupResponse, WhatsappMessageSendResponse
} from 'models/responses';

import ExternalMessageApi from "./ExternalMessageApi";

const sendSMSMessage = async (data: SMSMessageSendRequest, token: string) =>
  ExternalMessageApi.post<SMSMessageSendResponse, SMSMessageSendRequest>('messages/v1/send', token, data);

const sendViberMessage = async (data: ViberMessageSendRequest, token: string) =>
  ExternalMessageApi.post<ViberMessageSendResponse, ViberMessageSendRequest>('viber/v1/send', token, data);

const sendVerifyOtp = async (data: VerifyApiSendOTPRequest, token: string) =>
  ExternalMessageApi.post<VerifyApiSendOTPResponse, VerifyApiSendOTPRequest>('verify/v1/otp/send-otp', token, data);

const reSendVerifyOtp = async (data: VerifyApiResendOTPRequest, token: string) =>
  ExternalMessageApi.post<VerifyApiResendOTPResponse, VerifyApiResendOTPRequest>('verify/v1/otp/resend-otp', token, data);

const verifyVerifyOtp = async (data: VerifyApiVerifyOTPRequest, token: string) =>
  ExternalMessageApi.post<VerifyApiVerifyOTPResponse, VerifyApiVerifyOTPRequest>('verify/v1/otp/verify-otp', token, data);

const getNumberLookup = async (data: NumberLookupRequest, token:string) =>
  ExternalMessageApi.post<NumberLookupResponse, NumberLookupRequest>('hlr/v1/lookup', token, data);

const sendMetaWhatsappMessage = async (data: WhatsappMessageSendRequest, token: string) =>
  ExternalMessageApi.post<WhatsappMessageSendResponse, WhatsappMessageSendRequest>('whatsapp/v2/send', token, data);

export {
  sendSMSMessage,
  sendViberMessage,
  verifyVerifyOtp,
  sendVerifyOtp,
  reSendVerifyOtp,
  getNumberLookup,
  sendMetaWhatsappMessage
};
