import React, { useState } from "react";
import moment from "moment/moment";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
  Upload,
  message as antdMessage,
} from "antd";
import ChatPreviewPage from "components/whatsapp-meta/WhatsappTemplate/WhatsappTemplatePreview";
import {
  CloseCircleFilled,
  CloudUploadOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { WhatsappCarousel, WhatsappMediaTemplate } from "models";
import { uploadMediaFileService } from "services/dashboard/WhatsappService";
import { showErrorNotification } from "utils/helper";
import CampaignDynamicCarouselComponent from "../WhatsappCampaign/CampaignDynamicCarouselComponent";

const { Text } = Typography;

interface TemplateMessageProps {
  open: boolean;
  setOpenDrawer: any;
  utilityTemplates: any[];
  isLoadingTemplates: boolean;
  selectedOriginator: any;
  submitTemplateMessage:any,
}

const chatContainerStyle: React.CSSProperties = {
  margin: "20px auto",
  padding: "10px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  background: `url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
};


const TemplateMessage: React.FC<TemplateMessageProps> = ({
  open,
  setOpenDrawer,
  utilityTemplates,
  isLoadingTemplates,
  selectedOriginator,
  submitTemplateMessage,
}) => {
  const [messageForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [bodyParameters, setBodyParameters] = useState<{
    [key: string]: string;
  }>({});
  const [fileList, setFileList] = useState<any>([]);
  const [buttonTextValues, setButtonTextValues] = useState<string[]>([]);
  const [buttonPhoneTextValues, setButtonPhoneTextValues] = useState<string[]>(
    []
  );
  const [buttonCopyCodeTextValues, setButtonCopyCodeTextValues] = useState<string[]>([]);
  const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
  const [buttonType, setButtonType] = useState("");
  const [actionType, setActionType] = useState("");

  const [uploadingFile, setUploadingFile] = useState(false);

  const [isCarousel, setIsCarousel] = useState<boolean>(false);
  const [carouselComponent, setCarouselComponent] =
    useState<WhatsappCarousel>();
  const [inputDirection, setInputDirection] = useState("ltr");
  const [parameterValue, setParameterValue] = useState("");


  const disabledDate = (current: any) =>
    current && current < moment().endOf("day");

  const [selectedTemplate, setSelectedTemplate] = useState({
    template_id: "",
    template_language: "",
    body_text: "",
    example_body_text: [],
    header_text: "",
    header_format: "",
    footer_text: "",
    example_header_text: [],
    media_url: "",
    buttons: {
      buttons_type: "",
      button_quick_reply: [{ button_text_quick_reply: "" }],
      button_phone_number: [
        { button_text_phone_number: "", button_phone_number: "" },
      ],
      button_url: [
        { button_text_url: "", button_url: "", example_url_values: "" },
      ],
      button_copy_code: [{ button_text_copy_code: "" }],
    },
    limited_time_offer: { has_expiration: false, limited_time_offer_text: "" },
    carousel: {
      components: [
        {
          media_url: "",
          body_text: "",
          example_body_text: [],
          buttons: {
            button_url: [
              { button_text_url: "", button_url: "", example_url_values: "" },
            ],
          },
        },
      ],
    },
  });

  const resetAllStates = () => {
    setSelectedTemplate({
      template_id: "",
      template_language: "",
      body_text: "",
      example_body_text: [],
      header_text: "",
      header_format: "",
      footer_text: "",
      example_header_text: [],
      media_url: "",
      buttons: {
        buttons_type: "",
        button_quick_reply: [{ button_text_quick_reply: "" }],
        button_phone_number: [
          { button_text_phone_number: "", button_phone_number: "" },
        ],
        button_url: [
          { button_text_url: "", button_url: "", example_url_values: "" },
        ],
        button_copy_code: [{ button_text_copy_code: "" }],
      },
      limited_time_offer: {
        has_expiration: false,
        limited_time_offer_text: "",
      },
      carousel: {
        components: [
          {
            media_url: "",
            body_text: "",
            example_body_text: [],
            buttons: {
              button_url: [
                { button_text_url: "", button_url: "", example_url_values: "" },
              ],
            },
          },
        ],
      },
    });
    setOpenDrawer(false);

    setFileList([]);
    setBodyParameters({});
    setParameterValue("");
    setInputDirection("ltr");
    setButtonTextValues([]);
    setButtonPhoneTextValues([]);
    setButtonCopyCodeTextValues([]);
    setButtonUrlTextValues([]);
    setButtonType("");
    setActionType("");
    setIsCarousel(false);
    setDisableNext(false);
    setCarouselComponent(undefined);
    messageForm.resetFields();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await messageForm.validateFields();
      const values = messageForm.getFieldsValue();

      if (
        selectedTemplate?.example_body_text &&
        Object.keys(selectedTemplate?.example_body_text).length !==
          Object.keys(bodyParameters).length
      ) {
        showErrorNotification("Please enter all body parameters");
      } else {
        const WhatsappContent: WhatsappMediaTemplate = {
          template_id: selectedTemplate?.template_id || "",
          language: selectedTemplate?.template_language || "",
          body_parameter_values: bodyParameters || selectedTemplate.example_body_text,
          // eslint-disable-next-line no-nested-ternary
          media: selectedTemplate.header_format
            ? (selectedTemplate.header_format === 'TEXT' &&
               Object.keys(selectedTemplate.example_header_text).length === 0)
              ? undefined // Set media to undefined if TEXT format and no example_header_text
              : {
                  media_type: values.content?.media?.media_type || null,
                  media_url: values.content?.media?.media_url || selectedTemplate.media_url,
                  text_header_title:
                    selectedTemplate.header_format === "TEXT"
                      ? values.content?.media?.text_header_title || null
                      : "",
                  document_file_name:
                    selectedTemplate.header_format === "DOCUMENT"
                      ? values.content?.media?.document_file_name || null
                      : "",
                  media_caption: values.content?.media?.media_caption || "",
                  location:
                    selectedTemplate.header_format === "LOCATION"
                      ? {
                          longitude: values.content?.media?.location?.longitude || "",
                          latitude: values.content?.media?.location?.latitude || "",
                          name: values.content?.media?.location?.name || "",
                          address: values.content?.media?.location?.address || "",
                        }
                      : undefined,
                }
            : undefined,
          limited_time_offer: values.content?.limited_time_offer,
          carousel: isCarousel ? carouselComponent : undefined,
          buttons: values.content?.buttons,
        };

        let buttonIndex: number = -1;
        let quickReply = -1;
        selectedTemplate?.buttons?.button_quick_reply?.forEach((button, index) => {
          if (button) {
            quickReply += 1;
            buttonIndex += 1;
            if (values.content?.buttons?.quick_replies[quickReply]?.button_payload) {
              WhatsappContent.buttons!.quick_replies[quickReply] = {
                button_index: String(index),
                button_payload: values.content?.buttons?.quick_replies[quickReply]?.button_payload
              };
            } else {
              values.content?.buttons?.quick_replies.splice(quickReply, 1);
              quickReply -= 1;
            }
          }
        });

        if (selectedTemplate?.buttons?.button_phone_number?.length > 0) {
          buttonIndex += 1;
        }

        if (selectedTemplate?.limited_time_offer) {
          if (
            selectedTemplate?.buttons?.button_copy_code?.length > 0 &&
            selectedTemplate?.buttons?.button_copy_code[0]?.button_text_copy_code
          ) {
            buttonIndex += 1;
            WhatsappContent.buttons!.coupon_code = [{
              index: String(buttonIndex),
              coupon_code: values.content?.buttons?.coupon_code[0]?.coupon_code
            }];
          }

          selectedTemplate?.buttons?.button_url?.forEach((button, index) => {
            if (button && button.example_url_values) {
              buttonIndex += 1;
              if (values.content?.buttons?.actions[index]?.action_payload) {
                WhatsappContent.buttons!.actions[index] = {
                  action_index: String(buttonIndex),
                  action_type: "URL",
                  action_payload: values.content?.buttons?.actions[index]?.action_payload
                };
              }
            } else {
              buttonIndex += 1;
            }
          });
        } else {
          selectedTemplate?.buttons?.button_url?.forEach((button, index) => {
            if (button && button.example_url_values) {
              buttonIndex += 1;
              if (values.content?.buttons?.actions[index]?.action_payload) {
                WhatsappContent.buttons!.actions[index] = {
                  action_index: String(buttonIndex),
                  action_type: "URL",
                  action_payload: values.content?.buttons?.actions[index]?.action_payload
                };
              }
            } else {
              buttonIndex += 1;
            }
          });

          if (
            selectedTemplate?.buttons?.button_copy_code?.length > 0 &&
            selectedTemplate?.buttons?.button_copy_code[0]?.button_text_copy_code
          ) {
            buttonIndex += 1;
            WhatsappContent.buttons!.coupon_code = [{
              index: String(buttonIndex),
              coupon_code: values.content?.buttons?.coupon_code[0]?.coupon_code
            }];
          }
        }

        // Check the header format and set media_type accordingly
        if (selectedTemplate.header_format === 'IMAGE') {
          WhatsappContent.media!.media_type = 'image';
        } else if (selectedTemplate.header_format === 'VIDEO') {
          WhatsappContent.media!.media_type = 'video';
        } else if (selectedTemplate.header_format === 'DOCUMENT') {
          WhatsappContent.media!.media_type = 'document';
        } else if (selectedTemplate.header_format === 'AUDIO') {
          WhatsappContent.media!.media_type = 'audio';
        } else if (selectedTemplate.header_format === 'LOCATION') {
          WhatsappContent.media!.media_type = 'location';
        } else if (
          selectedTemplate.header_format === 'TEXT' &&
          Object.keys(selectedTemplate.example_header_text).length === 0
        ) {
          WhatsappContent.media = undefined;
        } else if (selectedTemplate.header_format === 'TEXT') {
          WhatsappContent.media!.media_type = 'text';
          WhatsappContent.media!.text_header_title =
            selectedTemplate.example_header_text &&
            values.content?.media?.text_header_title
              ? values.content?.media?.text_header_title
              : null;
        }

        submitTemplateMessage(WhatsappContent);
        resetAllStates();
      }
    } catch (error) {
      console.error("Failed to submit form:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleTemplateChange = (templateId: any) => {
    setButtonType("");
    setActionType("");
    setBodyParameters({});
    setIsCarousel(false);
    setDisableNext(false);
    const templateID = templateId?.split("-")[0] || "";
    const selected = utilityTemplates?.find(
      (template) => template.template_id === templateID
    );

    if (selected) {
      if (selected?.carousel?.components?.length > 0) {
        setIsCarousel(true);
        setDisableNext(true);
      }
      // @ts-ignore
      setSelectedTemplate(selected);

      const quickReplyButtons = selected?.buttons?.button_quick_reply || [];
      const phoneButtons = selected?.buttons?.button_phone_number || [];
      const urlButtons = selected?.buttons?.button_url || [];
      const copyCodeButtons = selected?.buttons?.button_copy_code || [];

      const quickReplyTextValues = quickReplyButtons.map(
        (button: any) => button.button_text_quick_reply || ""
      );
      const phoneTextValues = phoneButtons.map(
        (button: any) => button.button_text_phone_number || ""
      );
      const urlTextValues = urlButtons.map(
        (button: any) => button.button_text_url || ""
      );
      const copyCodeTextValues = copyCodeButtons.map((button:any) => button.button_text_copy_code || '');

      setButtonTextValues(quickReplyTextValues);
      setButtonPhoneTextValues(phoneTextValues);
      setButtonUrlTextValues(urlTextValues);
      setButtonCopyCodeTextValues(copyCodeTextValues);

      const rtlLanguages = ["ar", "he", "fa", "ur"]; // List of RTL language codes
      if (rtlLanguages.includes(selected?.template_language)) {
        setInputDirection("rtl");
      } else {
        setInputDirection("ltr");
      }
    }
  };
  const onCarouselSave = (carousel: WhatsappCarousel, isAllSaved: boolean) => {
    setDisableNext(isAllSaved);
    setCarouselComponent(carousel);
  };

  const handleParameterChange = (value: any) => {
    setParameterValue(value);
  };

  const handleBodyParameterChange = (key: any, value: any) => {
    setBodyParameters({
      ...bodyParameters,
      [key]: value,
    });
  };


  const fileUploadCustomRequest = (options: any) => {
    const fileType = options.file.type;
    const fileSize = options.file.size / 1024 / 1024;
    const doc = [
      "application/pdf",
      "text/plain",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    const image = ["image/jpeg", "image/png"];
    const video = ["video/3gp", "video/mp4"];
    if (
      (doc.includes(fileType) && fileSize > 100) ||
      (image.includes(fileType) && fileSize > 5) ||
      (video.includes(fileType) && fileSize > 16)
    ) {
      antdMessage.error("File size is too large!");
      setUploadingFile(false);
      setFileList([]);
    } else {
      setUploadingFile(true);
      const data = new FormData();
      data.append("file", options.file);
      uploadMediaFileService(selectedOriginator, options.file)
        .then((response) => {
          if (response.id !== "None") {
            options.onSuccess(response, options.file);
            setUploadingFile(false);
          } else {
            antdMessage.error(`${options.file} file type is not supported.`);
            setUploadingFile(false);
          }
        })
        .catch((error) => {
          messageForm.setFieldsValue({ content: { media: { media_url: "" } } });

          showErrorNotification(error.message);
          setUploadingFile(false);
          setFileList([]);
        });
    }
  };

  const onChangeFile = (info: any) => {
    setFileList(info.fileList);
    const { status, response } = info.file;
    if (status === "done") {
      antdMessage.success(`${info.file.name} file uploaded successfully.`);
      messageForm.setFieldsValue({
        content: { media: { media_url: response.id } },
      });
    } else if (status === "error") {
      setFileList([]);
      messageForm.setFieldsValue({ content: { media: { media_url: "" } } });
      antdMessage.error(`${info.file.name} file upload failed.`);
    } else if (status === "removed") {
      setFileList([]);
      messageForm.setFieldsValue({ content: { media: { media_url: "" } } });
    }
  };

  const onClose = () => {
    resetAllStates();
  };

  // components for template view
  const renderUploadFormItem = (label: any, accept: any) => (
    <Form.Item
      labelCol={{ span: 24 }}
      label={<span>{label}</span>}
      style={{ flexDirection: "row" }}
      name={["content", "media", "media_url"]}
      rules={[{ required: true, message: "Please upload a file" }]}
    >
      <Upload
        name="file"
        accept={accept}
        maxCount={1}
        customRequest={fileUploadCustomRequest}
        onChange={onChangeFile}
        fileList={fileList}
        showUploadList={{
          showRemoveIcon: true,
          removeIcon: <DeleteOutlined />,
        }}
      >
        <Button type="default" className="bg-gray-lightest mx-2 px-5 w-100">
          <CloudUploadOutlined /> Upload
        </Button>
      </Upload>
    </Form.Item>
  );

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title={null}
      footer={null}
      width={450}
      className="template-modal"
      bodyStyle={{ padding: 0, height: "calc(100vh - 100px)" }}
      centered
      maskClosable
      destroyOnClose
    >
      <Form
        layout="vertical"
        size="small"
        form={messageForm}
        onFinish={handleSubmit}
      >
        <div className="modalHeader">
          <h3 className='modalHeaderTitle'>Send Template</h3>
        </div>
        <div className="modalBody">
          <Scrollbars autoHide style={{ flex: 1 }}>
            <div className="modalContentWrapper">
              <Spin spinning={isLoadingTemplates}>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Select
                      aria-label="Choose your template"
                      placeholder="Choose your template"
                      onChange={handleTemplateChange}
                      showSearch
                      style={{ width: "100%", marginBottom: "10px" }}
                      filterOption={(input: any, option: any) =>
                        (option?.key &&
                          option.key
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0) ||
                        (option?.title &&
                          option.title
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0)
                      }
                    >
                      {utilityTemplates.length === 0 ? (
                        <Select.Option value="addTemplate">
                          <a href="/whatsapp-meta/whatsapp-meta-template">
                            Create New Template
                          </a>
                        </Select.Option>
                      ) : (
                        utilityTemplates.map((template) => (
                          <Select.Option
                            key={template.template_id}
                            value={template.template_id}
                          >
                            {template.template_name}
                          </Select.Option>
                        ))
                      )}
                    </Select>

                    {selectedTemplate && (
                      <div>
                        <Col xs={24} sm={24} md={24} className="mt-5">
                          {selectedTemplate.header_format === "IMAGE" &&
                            renderUploadFormItem(
                              "Upload Image Header",
                              ".jpeg, .png"
                            )}
                          {selectedTemplate.header_format === "VIDEO" &&
                            renderUploadFormItem(
                              "Upload Video Header",
                              ".3gp, .mp4"
                            )}
                          {selectedTemplate.header_format === "DOCUMENT" &&
                            renderUploadFormItem(
                              "Upload Document Header",
                              ".txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf"
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          {selectedTemplate.header_format &&
                            selectedTemplate.header_format === "DOCUMENT" && (
                              <Form.Item
                                label="File Name"
                                name={[
                                  "content",
                                  "media",
                                  "document_file_name",
                                ]}
                                labelCol={{ span: 24 }}
                              >
                                <Input />
                              </Form.Item>
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          {selectedTemplate.header_format &&
                            selectedTemplate.header_format === "TEXT" &&
                            Object.keys(selectedTemplate.example_header_text)
                              .length > 0 && (
                              <Form.Item
                                label="Header Parameters"
                                name={["content", "media", "text_header_title"]}
                                labelCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Header text!",
                                  },
                                ]}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Input
                                    value={parameterValue}
                                    onChange={(e) =>
                                      handleParameterChange(e.target.value)
                                    }
                                    dir={inputDirection}
                                  />
                                </div>
                              </Form.Item>
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          {selectedTemplate.header_format === "LOCATION" && (
                            <Row gutter={16}>
                              <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                  label="Longitude"
                                  name={[
                                    "content",
                                    "media",
                                    "location",
                                    "longitude",
                                  ]}
                                  labelCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter longitude!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                  label="Latitude"
                                  name={[
                                    "content",
                                    "media",
                                    "location",
                                    "latitude",
                                  ]}
                                  labelCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter latitude!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                  label="Name"
                                  name={[
                                    "content",
                                    "media",
                                    "location",
                                    "name",
                                  ]}
                                  labelCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter name!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                  label="Address"
                                  name={[
                                    "content",
                                    "media",
                                    "location",
                                    "address",
                                  ]}
                                  labelCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter address!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                          {selectedTemplate.body_text &&
                            selectedTemplate.example_body_text &&
                            Object.keys(selectedTemplate.example_body_text)
                              .length > 0 && (
                              <Form.Item
                                label="Body Parameters"
                                name={["content", "body_parameter_values"]}
                                labelCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter body parameter values!",
                                  },
                                ]}
                              >
                                <div>
                                  {Object.keys(
                                    selectedTemplate.example_body_text
                                  ).map((key, index) => (
                                    <div
                                      key={key}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <span
                                        style={{ marginRight: "10px" }}
                                      >{`${key}:`}</span>
                                      <Input
                                        value={bodyParameters[key] || ""}
                                        onChange={(e) =>
                                          handleBodyParameterChange(
                                            key,
                                            e.target.value
                                          )
                                        }
                                        dir={inputDirection}
                                      />

                                    </div>
                                  ))}
                                </div>
                              </Form.Item>
                            )}
                        </Col>
                        {selectedTemplate?.limited_time_offer
                          ?.has_expiration && (
                          <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item
                              label="Expiration DateTime(UTC)"
                              name={[
                                "content",
                                "limited_time_offer",
                                "expiration_time_ms",
                              ]}
                              labelCol={{ span: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter future datetime!",
                                },
                              ]}
                            >
                              <DatePicker
                                disabledDate={disabledDate}
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                className="w-100"
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {selectedTemplate.buttons ? (
                          <>
                            {selectedTemplate.buttons?.button_quick_reply
                              ?.length > 0 && (
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                style={{ display: "none" }}
                              >
                                <Form.Item /* eslint-disable react/no-array-index-key */
                                >
                                  {selectedTemplate.buttons?.button_quick_reply.map(
                                    (button, index) => (
                                      <div key={index}>
                                        {button.button_text_quick_reply && (
                                          <Form.Item
                                            label={`Quick Reply ${index + 1}`}
                                            name={[
                                              "content",
                                              "buttons",
                                              "quick_replies",
                                              index,
                                              "button_payload",
                                            ]}
                                            labelCol={{ span: 24 }}
                                          >
                                            <Input dir={inputDirection} />
                                          </Form.Item>
                                        )}
                                      </div>
                                    )
                                  )}
                                </Form.Item>
                              </Col>
                            )}
                            {selectedTemplate.buttons?.button_url?.length >
                              0 && (
                              <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item /* eslint-disable react/no-array-index-key */
                                >
                                  {selectedTemplate.buttons?.button_url.map(
                                    (button, index) => (
                                      <div key={index}>
                                        {button.example_url_values && (
                                          <Form.Item
                                            label="Dynamic URL Variable"
                                            name={[
                                              "content",
                                              "buttons",
                                              "actions",
                                              index,
                                              "action_payload",
                                            ]}
                                            labelCol={{ span: 24 }}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please enter Buttons Dynamic URL!",
                                              },
                                            ]}
                                          >
                                            <Input />
                                          </Form.Item>
                                        )}
                                      </div>
                                    )
                                  )}
                                </Form.Item>
                              </Col>
                            )}
                            {selectedTemplate.buttons?.button_copy_code
                              ?.length > 0 && (
                              <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item /* eslint-disable react/no-array-index-key */
                                >
                                  {selectedTemplate.buttons?.button_copy_code.map(
                                    (button: any, index: number) => (
                                      <div key={index}>
                                        {button.button_text_copy_code && (
                                          <Form.Item
                                            label="Coupon Code"
                                            name={[
                                              "content",
                                              "buttons",
                                              "coupon_code",
                                              0,
                                              "coupon_code",
                                            ]}
                                            labelCol={{ span: 24 }}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please enter valid code!",
                                              },
                                              {
                                                max: 15,
                                                message:
                                                  "Offer code cannot exceed 16 characters",
                                              },
                                            ]}
                                          >
                                            <Input />
                                          </Form.Item>
                                        )}
                                      </div>
                                    )
                                  )}
                                </Form.Item>
                              </Col>
                            )}
                          </>
                        ) : (
                          []
                        )}
                        {isCarousel && (
                          <Col xs={24} sm={24} md={24} lg={24}>
                            <CampaignDynamicCarouselComponent
                              fromWhatsappInbox
                              template={selectedTemplate}
                              onFinishedCard={onCarouselSave}
                              inputDirection={inputDirection}
                              selectedOriginator={selectedOriginator}
                            />
                          </Col>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
                {selectedTemplate && (
                  <Row gutter={16}>
                    <Text>Preview</Text>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      style={chatContainerStyle}
                    >
                      <ChatPreviewPage
                        headerText={selectedTemplate.header_text}
                        bodyText={selectedTemplate.body_text}
                        footerText={selectedTemplate.footer_text}
                        selectedMediaType={selectedTemplate.header_format}
                        buttonType={buttonType}
                        actionType={actionType}
                        actionButtonsQuickReply={buttonTextValues}
                        actionButtonsPhoneNumber={buttonPhoneTextValues}
                        actionButtonsUrl={buttonUrlTextValues}
                        buttonCopyCodeTextValues={buttonCopyCodeTextValues}
                        isCarousel={isCarousel}
                        inputDirection={inputDirection}
                        optOutButton={false}
                      />
                    </Col>
                  </Row>
                )}
              </Spin>
            </div>
          </Scrollbars>
          <div className="modalFooter">
            <Row justify="space-between" gutter={8}>
              <Col>
                <CloseCircleFilled
                  onClick={onClose}
                  style={{
                    fontSize: 40,
                    color: "rgba(145, 145, 145, 0.5)",
                    cursor: "pointer",
                  }}
                />
              </Col>
              <Col>
                <SendOutlined
                  onClick={() => messageForm.submit()}
                  style={{
                    fontSize: 38,
                    color: "rgba(145, 145, 145, 0.5)",
                    cursor:
                      loading || disableNext || uploadingFile
                        ? "not-allowed"
                        : "pointer",
                  }}
                  type="submit"
                  disabled={loading || disableNext || uploadingFile}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default TemplateMessage;
