import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationPopup from "components/layout/WhatsappNotification";
import { AblyInstanceContext } from "hooks/useAbly";
import useAblyChannel from "hooks/useAblyChannel";
import { checkNumberIntervenedService, listWhatsappChatService, listWhatsappContactService } from "services/dashboard/WhatsappService";
import { WhatsappMetaChat } from "models";
import { ChatItem } from "components/whatsapp-meta/WhatsappInbox/Conversation";
import { removeSpaces } from "utils/helper";


interface NotificationContextType {
  showNotification: (message: any) => void;
  newlySelectedOriginator: (originator: any) => void;
  currentOriginator: any; 
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

interface NotificationProviderProps {
  children?: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const inboxAblyClient = useContext(AblyInstanceContext);
  
  const [notification, setNotification] = useState<any | null>(null);
  const [chatbotConnected, setChatbotConnected] = useState(false);
  const [list, setList] = useState<ChatItem[]>([]);
  const [chats, setChats] = useState<ChatItem[]>([]);
  const [selectedOriginator, setSelectedOriginator] = useState<any>();
  const [newOriginator,setNewOriginator] = useState<any>();
  const [isVisible, setIsVisible] = useState(true);
  
  const normalizeNumber = (number: string) =>
    number?.startsWith("+")
      ? removeSpaces(number?.substring(1))
      : removeSpaces(number);
  const shouldSkipApiCall = location.pathname.startsWith("/whatsapp-meta/inbox");
  // Fetch WhatsApp contacts
  // const fetchPhoneNumbers = async () => {
  //   if (shouldSkipApiCall) return;
  //   try {
  //     const fetchedPhoneNumbers: any = await listWhatsappContactService();
  //     if (fetchedPhoneNumbers?.length > 0) {
  //       setSelectedOriginator(fetchedPhoneNumbers[0]);
  //       setChatbotConnected(fetchedPhoneNumbers[0]?.is_chatbot_enabled);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching phone numbers:", error);
  //   }
  // };
  useEffect(() => {
    const updateSelectedOriginator = async () => {
      if (shouldSkipApiCall) return;
      try {
        const fetchedPhoneNumbers: any = await listWhatsappContactService();
        if (fetchedPhoneNumbers?.length > 0) {
          const selected = newOriginator
            ? fetchedPhoneNumbers.find((row: any) => row.number === newOriginator.number)
            : fetchedPhoneNumbers[0]; 
  
          setSelectedOriginator(selected ?? fetchedPhoneNumbers[0]);
          setChatbotConnected(selected?.is_chatbot_enabled ?? false);

          setChats([]);
          // setList([]);
        }
      } catch (error) {
        console.error("Error updating selected originator:", error);
      }
    };
  
    updateSelectedOriginator();
  }, [newOriginator, shouldSkipApiCall]);
  
    const normalizeChats = (chatsArray: WhatsappMetaChat[]) =>
      chatsArray?.map((item: WhatsappMetaChat) => ({
        id: item.id,
        name: item.contact_name,
        avatar: item.contact_display_image ?? undefined,
        unread: item.unread_message_count,
        phone: item.contact_phone_number,
        lastMessage: {
          id: item.last_message.msg_id,
          from:
            normalizeNumber(item.last_message.originator) === normalizeNumber(selectedOriginator?.number)
              ? "me"
              : item.last_message.recipient,
          time: item.last_message.created_at,
          type: item.last_message.message_type,
          text:
            item.last_message.message_text
              ? item.last_message.message_text
              : `${item.last_message.message_type} message.`,
          status: item.last_message.status,
          content: item.last_message
        },
        expiration_timestamp: item.last_user_message?.msg_timestamp
        ? Number(item.last_user_message?.msg_timestamp) * 1000 + 24 * 60 * 60 * 1000
        : undefined,
      }));

  const fetchChatsByPhoneNumber = async (pageNumber: number, query: string) => {
    let fetchedChats: WhatsappMetaChat[] = [];
    if (shouldSkipApiCall) return;
    try {
      if (selectedOriginator?.number) {
        const normalizedNumber = normalizeNumber(selectedOriginator?.number);
        const result = await listWhatsappChatService(
          normalizedNumber,
          pageNumber,
          20,
          query
        );
       fetchedChats = result.chats;
        const chatIdArray = chats?.map((item)=>item.id);
        const filteredChats = fetchedChats?.filter((item)=> !chatIdArray.includes(item.id));
        if (filteredChats?.length > 0) {
          const normalizedChats = normalizeChats(filteredChats);
          setChats((prevChats) => [...prevChats, ...normalizedChats]);
          setList((prevChats) => [...prevChats, ...normalizedChats]);
        }
      }
    } catch (error) {
       console.log(error);
    }
  };

  const incomingMessage = useAblyChannel(
    inboxAblyClient,
    `channel_${normalizeNumber(selectedOriginator?.number)}`,
    Boolean(selectedOriginator?.number),
    false,
    isVisible
  );

  const showNotification = (message: any) => {
    if (shouldSkipApiCall || !isVisible) return; 
    setNotification(message);
    setTimeout(() => setNotification(null), 2000);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(document.visibilityState === "visible");
    };

    const handleBeforeUnload = () => {
      setIsVisible(false);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (incomingMessage?.name && incomingMessage?.data) {
      const msgParsed =
        typeof incomingMessage.data === "string"
          ? JSON.parse(incomingMessage.data)
          : incomingMessage.data;
      const whatsappMessages = msgParsed.whatsapp_messages?.[0]?.message;
      
      if (whatsappMessages?.length > 0) {
        const lastMessage = whatsappMessages[whatsappMessages.length - 1];
        const normalizedOriginator = lastMessage.originator?.startsWith("+")
          ? lastMessage.originator.substring(1)
          : lastMessage.originator;

        let messageText;
        if (lastMessage.status === "rejected") {
          messageText = "Meta rejected your last message.";
        } else if (lastMessage.message_text) {
          messageText = lastMessage.message_text;
        } else {
          messageText = `${lastMessage.message_type} message.`;
        }
        if (
          normalizedOriginator !== normalizeNumber(selectedOriginator?.number) 
        ) {
          const notificationObj = {
            id: lastMessage.msg_id,
            originator_number:lastMessage.originator,
            name: lastMessage.profile_name ?? "Unknown",
            text: lastMessage.attachment_type ? `${lastMessage.attachment_type.toUpperCase()}  ${messageText}` : messageText,
            phone: `+${lastMessage.originator}`,
            time: new Date(), 
          };
          showNotification(notificationObj);
        }
      }
    }
  }, [incomingMessage]);


  useEffect(() => {
    fetchChatsByPhoneNumber(1, "");
  }, [selectedOriginator]);

  // useEffect(() => {
  //   fetchPhoneNumbers();
  // }, []);

  const navigateToChat = (id: string, isNumberIntervened: boolean) => {
    const navigationData = {
      chatInfo: list.find((item) => item.id === id),
      originatorInfo: {...selectedOriginator, number: normalizeNumber(selectedOriginator?.number)},
      isChatbotConnected: chatbotConnected,
      chatIntervened: isNumberIntervened
    };
    navigate(`/whatsapp-meta/inbox/${id}`, { state: navigationData });
  };

  const openChat = async (id: string) => {
    const data = list.map((elm) => {
      const elmCopy = { ...elm };
      if (elmCopy.id === id) {
        elmCopy.unread = 0;
      }
      return elmCopy;
    });
   setList(data);
    let isNumberIntervened = false;
    if (chatbotConnected){
      const openedChat = list.find((item) => item.id === id);
      if (openedChat?.phone) {
        const perfectNumber = normalizeNumber(selectedOriginator?.number);
        isNumberIntervened = await checkNumberIntervenedService(perfectNumber, openedChat?.phone);
      }
    }

    navigateToChat(id, isNumberIntervened);
  };

  const contextValue = useMemo(() => ({ 
    showNotification,
    newlySelectedOriginator: setNewOriginator,
    currentOriginator: newOriginator
    
   }), [location.pathname,selectedOriginator,newOriginator]);


  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      {notification && (
        <NotificationPopup
          selectedOriginator={selectedOriginator.number}
          message={notification}
          onClose={() => openChat(notification.originator_number)}
        />
      )}
    </NotificationContext.Provider>
  );
};
NotificationProvider.defaultProps = {
  children: undefined,
};
export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

export default NotificationContext;
