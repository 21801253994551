import React, { useEffect, useMemo, useState } from 'react';
import { Table, Button, Tooltip, Menu, Dropdown, Input, Popconfirm } from 'antd';
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import { ColumnsType, ColumnType } from 'antd/es/table';
import debounce from "lodash.debounce";
import Flex from '../Flex';

export interface ContextMenuItem<RecordType> {
  title: string;
  onClick?: (selectedRows?: RecordType[]) => void
}
export interface ControlItem<RecordType> {
  title: string;
  icon?: React.ReactNode,
  isExport?: boolean,
  exportFileName?: string,
  onClick?: (selectedRows?: RecordType[]) => void,
  exportMapper?: (item: RecordType) => any,
  headers?: string[];
  buttonNotNeeded?: boolean;
  isPrimary?: boolean;
}
export interface PopConfirmProps<RecordType> {
  onCancel?: (item: RecordType, index: number) => void,
  onConfirm?: (item: RecordType, index: number) => void,
  title?: string,
  renderTitle?: (item: RecordType, index: number) => string,
}
export interface ActionItem<RecordType> {
  icon?: React.ReactNode;
  toolTip?: string;
  onClick?: (item: RecordType, index: number) => void;
  popConfirm?: PopConfirmProps<RecordType>;
  renderIcon?: (item: RecordType, index: number) => React.ReactNode;
  renderToolTip?: (item: RecordType, index: number) => string;
  renderer?: (item: RecordType, index: number, child?: React.ReactNode) => React.ReactNode;
  disabled?: boolean;
  getDisabled?: (item: RecordType, index: number) => boolean
}
export interface ServerSidePaginationOption {
  onChangePageSize?: (pageSize: number) => void;
  onChangePage?: (page: number, pageSize: number) => void;
  total?: number;
  currentPage?: number;
  pageSize?: number;
}

export interface rowSelectionProps {
  row_selection_disabled?: boolean;
}

export interface TableListProps<RecordType> {
  items: RecordType[];
  columns: ColumnsType<RecordType>;
  loading?: boolean;
  rowKey: (record: RecordType, index?: number) => React.Key;
  showSearch?: boolean;
  onSearch?: (text: string) => void,
  contextMenuItems?: ContextMenuItem<RecordType>[];
  controls?: ControlItem<RecordType>[];
  actions?: ActionItem<RecordType>[];
  searchPredicate?: (item: RecordType, text: string) => boolean;
  hideRowSelection?: boolean;
  serverSidePaginationOption?: ServerSidePaginationOption;
  scroll?: { x?: number | true | string; y?: number | string; };
  persistedSelectedRowKeys?: React.Key[];
  onChangeSelection?: (keys: React.Key[], rows: RecordType[]) => void;

  // unSelectAll?: boolean;
}

const TableList = <RecordType extends object = any>(props: TableListProps<RecordType>) => {
  const { items, loading, showSearch, contextMenuItems, controls, actions, columns, scroll,
    onSearch,
    rowKey,
    searchPredicate,
    hideRowSelection,
    serverSidePaginationOption,
    persistedSelectedRowKeys,
    onChangeSelection
  } = props;
  const [selectedRows, setSelectedRows] = useState<RecordType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filteredItems, setFilteredItems] = useState<RecordType[]>(items);

  const debouncedSearch = useMemo(() => onSearch && debounce(onSearch, 500), []);
  const menuOption = (
    <Menu>
      {contextMenuItems?.map(m => <Menu.Item
        onClick={() => m.onClick?.(selectedRows)} key={`cm_${m.title.replaceAll(" ", "_")}`}>
        <span>
          <div className="d-flex align-items-center">
            <span className="ml-2">{m.title}</span>
          </div>
        </span>
      </Menu.Item>)}
    </Menu>
  );
  const contextMenu = () => (
    <div className="text-right">
      <Dropdown overlay={menuOption} trigger={['click']} placement="bottomRight">
        <div className="ellipsis-dropdown">
          <EllipsisOutlined />
        </div>
      </Dropdown>
    </div>
  );
  const getActionItem = (a: ActionItem<RecordType>, record: RecordType, index: number) => {
    const disabledAction = a.disabled ?? a.getDisabled?.(record, index);
    if (a.popConfirm) {
      return (<Popconfirm
        placement="topRight"
        title={a.popConfirm.title ?? a.popConfirm?.renderTitle?.(record, index)}
        onConfirm={() => a.popConfirm?.onConfirm?.(record, index)}
        okText="Yes"
        cancelText="No"
        disabled={disabledAction}
      >
        {a.renderer ? a.renderer(record, index,
          <Button className="mr-2" icon={a.icon ?? a.renderIcon?.(record, index)} onClick={() => a.onClick?.(record, index)} size="small"
            disabled={disabledAction} />)
          : <Button className="mr-2" icon={a.icon ?? a.renderIcon?.(record, index)} onClick={() => a.onClick?.(record, index)} size="small"
            disabled={disabledAction} />}

      </Popconfirm>);
    }
    if (a.onClick) {
      return (
          <Button className="mr-2" icon={a.icon ?? a.renderIcon?.(record, index)} onClick={() => a.onClick?.(record, index)} size="small"
            disabled={disabledAction} />);
    }
    return (a.renderer ? a.renderer(record, index,
      <Button className="mr-2" icon={a.icon} onClick={() => a.onClick?.(record, index)} size="small" disabled={disabledAction} />)
      : <Button className="mr-2" icon={a.icon} onClick={() => a.onClick?.(record, index)} size="small" disabled={disabledAction} />);
  };
  const getTableColumns = () => {
    const actionColumn: ColumnType<RecordType> = { title: '', dataIndex: 'actions' };
    let showActionColumn = false;
    if ((contextMenuItems?.length ?? 0) > 0) {
      actionColumn.title = contextMenu();
      showActionColumn = true;
    }
    if ((actions?.length ?? 0) > 0) {
      showActionColumn = true;
      actionColumn.render = (_value: any, record: RecordType, index: number) => (
        <div className="text-right d-flex justify-content-end">
          {
            actions?.map(a => <Tooltip title={a.toolTip ?? a.renderToolTip?.(record, index)} key={`tt_${index}${a.toolTip}`}>
              {getActionItem(a, record, index)}

            </Tooltip>)}
        </div>
      );
    }
    if (showActionColumn) {
      return [...columns, actionColumn];
    }
    return columns;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: React.Key[], rows: RecordType[]) => {
      setSelectedRows(rows);
      setSelectedRowKeys(keys);
      if (persistedSelectedRowKeys) {
        const existingKeys = persistedSelectedRowKeys.filter(x => items.findIndex(y => rowKey(y) === x) < 0);
        onChangeSelection?.([...keys, ...existingKeys], rows);
      } else {
        onChangeSelection?.(keys, rows);
      }

    },
    getCheckboxProps: (record: rowSelectionProps) => ({
      disabled: record.row_selection_disabled, // Disable checkbox for rows where "disabled" is true
    }),
  };
  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (searchPredicate) {
      if (value && value.length > 0) {
        setFilteredItems(items.filter(item => searchPredicate(item, value.toLowerCase())));
      } else {
        setFilteredItems(items);
      }
    }
    debouncedSearch?.(value.toLowerCase());
  };

  const getPagination = () => serverSidePaginationOption ? {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    onChange: (page: number, size: number) => { serverSidePaginationOption?.onChangePage?.(page, size); },
    onShowSizeChange: (current: number, size: number) => { serverSidePaginationOption?.onChangePageSize?.(size); },
    current: serverSidePaginationOption?.currentPage,
    total: serverSidePaginationOption?.total,
    pageSize: serverSidePaginationOption?.pageSize,
    responsive: true,
  } : {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    responsive: true,
  };
  const getExportData = (controlItem: ControlItem<RecordType>) => {
    const exportedItems = selectedRows.length === 0 ? items : selectedRows;
    if (controlItem.exportMapper) {
      return exportedItems.map(x => controlItem.exportMapper?.(x));
    }
    return exportedItems;
  };
  const selectRows = () => {
    if (persistedSelectedRowKeys) {
      setSelectedRowKeys(persistedSelectedRowKeys);
    } else {
      setSelectedRowKeys([]);
    }
  };
  useEffect(() => {
    setFilteredItems(items);
    selectRows();
    return () => {
      debouncedSearch?.cancel();
    };
  }, [items]);

  return (
    <>
      <Flex alignItems="center" justifyContent={showSearch ? "between" : "end"} mobileFlex={false}>
        {showSearch && <Flex className="mb-3" mobileFlex={false}>
          <div>
            <Input placeholder="Search" prefix={<SearchOutlined />} onChange={onChangeSearchInput} />
          </div>
        </Flex>}

        {((controls?.length ?? 0) > 0) && <div className="text-right d-md-flex justify-content-end">
          {controls?.map((c) => c.isExport ? <CSVLink className="mr-2" key={`ctrl_exp_${c.title}`} data={getExportData(c)} filename={c.exportFileName}
            headers={c.headers}>
            <Button icon={c.icon} block onClick={() => c.onClick?.(selectedRows)}>{c.title}</Button>
          </CSVLink> :
            <div className='ml-2 mr-2 mb-3'>
              {
              (c.buttonNotNeeded === undefined || !c.buttonNotNeeded) ?
              <Button type={c.isPrimary?"primary": "default"} key={`ctrl_${c.title}`} icon={c.icon} block className="mr-2" onClick={() => c.onClick?.(selectedRows)}>{c.title}</Button>
                : c.icon}
            </div>
          )}
        </div>}
      </Flex>
      <div className="table-responsive">
        <Table<RecordType> columns={getTableColumns()} dataSource={filteredItems} pagination={getPagination()} rowSelection={hideRowSelection ? undefined : {
          type: 'checkbox',
          ...rowSelection,
        }}
          rowKey={rowKey}
          loading={loading}
          scroll={scroll}
        />
      </div>
    </>);
};
TableList.defaultProps = {
  showSearch: false,
  contextMenuItems: [],
  controls: [],
  actions: [],
  onSearch: undefined,
  loading: false,
  searchPredicate: undefined,
  hideRowSelection: false,
  serverSidePaginationOption: undefined,
  scroll: undefined,
  persistedSelectedRowKeys: undefined,
  onChangeSelection: undefined
};
export default TableList;
