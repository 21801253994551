import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faDownload,
  faFileAlt,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileText,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Progress } from "antd";

interface WhatsAppDocumentAttachmentProps {
  fileName?: string;
  fileSize?: string;
  downloadUrl: string;
  mediaSrc: string | null;
  handleDownload: () => void;
  isDownloading: boolean;
  downloadProgress: number;
  fileMimeType?: string;
}

const WhatsAppDocumentAttachment: React.FC<WhatsAppDocumentAttachmentProps> = ({
  fileName = "Untitled",
  fileSize,
  downloadUrl,
  mediaSrc,
  handleDownload,
  isDownloading,
  downloadProgress,
  fileMimeType,
}) => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    margin: "2px",
    backgroundColor: "whitesmoke",
    padding: "10px",
    width: "100%",
    cursor: "pointer",
    minWidth: "300px",
    maxWidth: "300px",
    gap: "10px",
  };

  const iconContainerStyle: React.CSSProperties = {
    flexShrink: 0,
  };

  const infoContainerStyle: React.CSSProperties = {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  };

  const fileNameStyle: React.CSSProperties = {
    fontWeight: 500,
    marginBottom: "4px",
    // ellipsis and max width
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "175px",
  };

  const fileSizeStyle: React.CSSProperties = {
    color: "#888",
    fontSize: "12px",
    textTransform: "uppercase",
  };

  const fileTypeStyle: React.CSSProperties = {
    color: "#888",
    fontSize: "12px",
    textTransform: "uppercase",
  };

  const fileSizeTypeSeparatorStyle: React.CSSProperties = {
    color: "#888",
    fontSize: "12px",
    margin: "0 5px",
  };

  const buttonStyle: React.CSSProperties = {
    zIndex: 2,
    fontSize: "12px",
    color: "white",
    minWidth: "35px",
    width: "35px",
    height: "35px",
  };

  const mimeToExtensionMap: { [key: string]: string } = {
    "text/plain": ".txt",
    "application/vnd.ms-excel": ".xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/vnd.ms-powerpoint": ".ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      ".pptx",
    "application/pdf": ".pdf",
   "audio/mpeg": ".mp3"
  };

  const downloadToDesktop = () => {
    if (mediaSrc) {
      const link = document.createElement("a");
      link.href = mediaSrc;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(mediaSrc);
    }
  };

  useEffect(() => {
    if (mediaSrc && !isDownloading && !downloadUrl.startsWith("http")) {
      downloadToDesktop();
    }
  }, [isDownloading, mediaSrc, downloadUrl]);

  const getIconBasedOnMimeType = (mimeType: any): React.ReactNode => {
    if(!mimeType)
      return <FontAwesomeIcon icon={faFileAlt} size="2x" color="#d32f2f" />;
    
    const extension = mimeToExtensionMap[mimeType];

    if (extension === ".txt") {
      return <FontAwesomeIcon icon={faFileText} size="2x" color="#d32f2f" />;
    }
    if (extension === ".xlsx" || extension === ".xls") {
      return <FontAwesomeIcon icon={faFileExcel} size="2x" color="#d32f2f" />;
    }
    if (extension === ".pptx" || extension === ".ppt") {
      return (
        <FontAwesomeIcon icon={faFilePowerpoint} size="2x" color="#d32f2f" />
      );
    }
    if (extension === ".docx" || extension === ".doc") {
      return <FontAwesomeIcon icon={faFileWord} size="2x" color="#d32f2f" />;
    }
    if (extension === ".pdf") {
      return <FontAwesomeIcon icon={faFilePdf} size="2x" color="#d32f2f" />;
    }
    return <FontAwesomeIcon icon={faFileAlt} size="2x" color="#d32f2f" />;
  };

  const getFileExtension = (mimeType: string): string => {
    const extension = mimeToExtensionMap[mimeType];
    return extension.substring(1);
  };

  const handleViewFile = () => {
    if (mediaSrc) {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleBtnClick = () => {
    if (downloadUrl?.startsWith("http")) {
      handleViewFile();
    } else {
      handleDownload();
    }
  };

  return (
    <div style={containerStyle} onClick={handleBtnClick} onKeyDown={() => handleBtnClick()} role="button" tabIndex={0}>
      <div style={iconContainerStyle}>
        {getIconBasedOnMimeType(fileMimeType)}
      </div>
      <div style={infoContainerStyle}>
        <span style={fileNameStyle}>{fileName || "Untitled"}</span>
        <div style={{ display: "flex", alignItems: "center" }}>
          {fileSize && <span style={fileSizeStyle}>{fileSize}</span>}
          {fileSize && fileMimeType && (
            <span style={fileSizeTypeSeparatorStyle}>•</span>
          )}
          {fileMimeType && (
            <span style={fileTypeStyle}>{getFileExtension(fileMimeType)}</span>
          )}
        </div>
      </div>

      {downloadUrl?.startsWith("http") ? (
        <div style={{ width: "35px" }}>
          <Button
            type="primary"
            shape="circle"
            icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            style={buttonStyle}
          />
        </div>
      ) : (
        <div style={{ width: "35px" }}>
          {!isDownloading ? (
            <Button
              type="primary"
              shape="circle"
              icon={<FontAwesomeIcon icon={faDownload} />}
              style={buttonStyle}
            />
          ) : (
            <Progress
              type="circle"
              percent={downloadProgress}
              strokeWidth={12}
              width={35}
              strokeColor="green"
            />
          )}
        </div>
      )}
    </div>
  );
};

WhatsAppDocumentAttachment.defaultProps = {
  fileSize: undefined,
  fileName: "Untitled",
  fileMimeType: undefined,
};

export default WhatsAppDocumentAttachment;
